import fetchJsonp from 'fetch-jsonp';

import { stripHTML } from '../functions/strip_html';

export default class DuckDuckGo {

    constructor(active) {
        this.active = active;
    }
    getResults(term) {
        if(this.active === false) {
            return Promise.resolve([]);
        }
        let queryterm = encodeURIComponent(term);
        var results = fetchJsonp('https://ac.duckduckgo.com/ac/?q=' + queryterm,
        {
            jsonpCallbackFunction: 'autocompleteCallback'
        }
        ).then(response => {
            console.log(response);
            return response.json();
        })
        .then(json => json.map(item => { return this.getParseResultItem(item); }));
        // .catch(error => []);

        return results;
    }

    getParseResultItem(item) {
        console.log(item);
        return { 
            source: 'duckduckgo', 
            title: item.phrase, 
            click_url: '', 
            image_url: '',
            strippedTitle: stripHTML(item.phrase)
        }; 
    }
}