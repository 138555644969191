import SuggestionNetworks from "../suggestions/suggestion_networks";
import { suggestionsFuzzy } from '../functions/suggestions_fuzzy';
import { suggestionsBlackFriday } from '../functions/suggestions_black_friday';

export default class TargetAd
{
    static adToUse(suggestions) {
        let found = suggestionsFuzzy(suggestions, ['target']) || suggestionsBlackFriday(suggestions, ['target']);
        if (found) {
            return {
                source: "adbistro",
                provider: "target_impact",
                title: "Shop @Target",
                click_url: "https://goto.target.com/c/1248655/81938/2092?subId1=toolbar&subId2=homepage&u=https%3A%2F%2Fwww.target.com%2F",
                image_url: "https://fsquizmast.blob.core.windows.net/images/1_adbistro/20181030/target-eIH2V9dKAMA.png"
            };
        } else {
            return null;
        }
    }
}
