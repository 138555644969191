import { startsWith } from './starts_with';


exports.suggestionsFuzzy = function(suggestions, testStrings) {
    let found = false;
    suggestions.forEach((suggestion) => {
        let suggestionLowered = suggestion.strippedTitle.toLowerCase();

        testStrings.forEach((testString) => {
            if(startsWith(suggestionLowered, testString)) {
                found = true;
            }
        });
    });
    return found;
};