
export default class Mocha {

    constructor(config) {
        this.active = config.adProvider.includes("mocha");
    }
    getAd(term) {
        if(this.active === false) {
            return Promise.resolve([]);
        }
        const data = {
            "client_id": "BQoLAQ8LAwsOAgsPBA8GDQ",
            "campaign_id": "CAQMCQoMAQMJBAMLBA8FBw",
            "text": term
        }
        let results = fetch('https://api.searchemoji.global/search',{
            method: 'POST',
            headers:{
                "Authorization": "Bearer DA8FBQUDCAQDDAUEBA4FBQ",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        })
        .then(response => response.json())
        .then(json => json.map(item => { return this.getParseResultItem(item); }))
        .then(json => this.whiteListAds(json))
        .then(json => json.length > 0 ? json : [])
        .catch(error => []);
        return results;
    }

    getParseResultItem(item) {
        return { 
            source: 'mocha', 
            provider: 'mocha',
            title: item.shortName, 
            click_url: item.link, 
            image_url: item.creatives.logosqr
        }; 
    }

    whiteListAds(results) {
        const whitelist = [
                            // 'bestbuy.com',
                            'groupon',
                            'hotwire',
                            // 'hsn',
                            // 'lowes.com',
                            'qvc',
                            'samsclub.com',
                            // 'shutterfly',
                            'shutterstock',
                            'walmart.com',
                            'wayfair.com'
                        ];
        return results.filter(result => whitelist.indexOf(result.title.toLowerCase()) > -1);
    }

}