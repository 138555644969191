export default {
    Ads: [
        {
            keywords: ['bookingbuddy','booking buddy'],
            source: "adbistro", 
            title: "Booking Buddy", 
            click_url: "https://prf.hn/click/camref:1101l4nMX/destination:http:/www.bookingbuddy.com/en-US/hero/?mode=air&currency=USD&tab_limit=7", 
            image_url: "https://fsquizmast.blob.core.windows.net/images/1_adbistro/20181019/bookingbuddy-W5uH1XLPAYA.png"
        }
    ]
}