import AdBistro from './ad_bistro';
import AdMarketplace from './ad_marketplace';
import Mocha from './mocha';
import Namespace from './namespace';

export default class AdNetworks {

    constructor(config) {
        this.config = config;
        this.ad_bistro = new AdBistro(this.config);
        this.ad_marketplace = new AdMarketplace(this.config);
        this.mocha = new Mocha(this.config);
        this.namespace = new Namespace(this.config);
    }
    getAd(term) {
        if(this.config.adNetworksEnabled === false) {
            return Promise.resolve(null);
        }
        let adBistroResults = this.ad_bistro.getAd(term);
        let admResults = this.ad_marketplace.getAd(term);
        let mochaResults = this.mocha.getAd(term);
        let namespaceResults = this.namespace.getAd(term);

        let ad = Promise.all([adBistroResults, admResults, mochaResults, namespaceResults])
            .then(([adBistroResultsResolved, admResultsResolved, mochaResultsResolved, namespaceResultsResolved]) => {
                let result = null;
                let resolvedResults = {
                    "direct": adBistroResultsResolved,
                    "adm": admResultsResolved,
                    "mocha": mochaResultsResolved,
                    "namespace": namespaceResultsResolved
                };
                for (let provider of this.config.adProvider) {
                    if(resolvedResults[provider].length > 0) {
                        result = resolvedResults[provider][0];
                        break;
                    }
                }
                return result;
            });

        return ad;
    }
}