import SuggestionNetworks from './suggestions/suggestion_networks';
import AdNetworks from './ads/ad_networks';

import CustomAds from './custom_ads/custom_ads';
import { shouldBeAtBottom } from './functions/ad_at_bottom';
import { impressionEventFromSource } from './functions/impression_event_from_source';
import { startsWith } from './functions/starts_with';

export default class Autosuggest {

    constructor(config) {
        this.config = config || { suggestionProvider: ['google'], autoselectTopResult: true, fuzzyAd: true, adProvider: '', typeTag: 'ckyolt', overrideTypeTag: false };
        
        this.ad_networks = new AdNetworks(config);
        this.suggestion_networks = new SuggestionNetworks(config);
        this.custom_ads = new CustomAds(config);
    }

    getSuggestions(term) {
        if (term.length < 3 || term.length > 100) {
            return Promise.resolve([]);
        }
        let ad = this.ad_networks.getAd(term);
        let suggestions = this.suggestion_networks.getResults(term);
        return Promise.all([ad, suggestions]).then(([adResolved, suggestionsResolved]) => {    
            if(this.config.fuzzyAd) 
            {
                if(adResolved === null || adResolved.source !== "adbistro") {
                    let customAd = this.custom_ads.getAd(suggestionsResolved, term);
                    if(customAd !== null) {
                        adResolved = customAd;
                    }
                }
            }

            if(adResolved !== null) {
                let adShouldbeAtBottom = shouldBeAtBottom(adResolved, suggestionsResolved);
                adResolved.impression_event_name = impressionEventFromSource(adResolved.provider);
                console.log(adResolved);
                suggestionsResolved.unshift(adResolved);
                if(adResolved.source === "adbistro" || adResolved.source === "mocha") {
                    if(adResolved.title === 'Tax Act' && !(startsWith(term, "taxa") || startsWith(term, "tax a"))) {
                        suggestionsResolved[1].autoselect = true;
                    } else {
                        suggestionsResolved[0].autoselect = true;
                    }
                } else {
                    if(adShouldbeAtBottom) {
                        suggestionsResolved[1].autoselect = true;
                    } else {
                        suggestionsResolved[0].autoselect = true;
                    }
                }
            } else {
                suggestionsResolved[0].autoselect = true;
            }
            return suggestionsResolved;
        });
    }
}