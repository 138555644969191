
exports.fudge = function(results) {
    if(results.length === 0) {
        return results;
    }
    const strippedText = results[0].strippedTitle;
    if(hardcodedFirsts[strippedText] !== undefined) {
        const textToPutFirst = hardcodedFirsts[strippedText];
        let filteredResults = results.filter(result => {
            return result.strippedTitle !== textToPutFirst;
        })
        filteredResults.unshift({ source: results[0].source, title: textToPutFirst, click_url: '', image_url: '', strippedTitle: textToPutFirst });
        return filteredResults;
    }
    return results;
};

const hardcodedFirsts = {
    '1040 tax return 2018': '1040 tax form instructions 2018',
'1040 tax form for 2018': '1040 tax form instructions 2018',
'1040ez 2018 tax table': '1040 tax form instructions 2018',
'1040 tax from 2016': '1040 tax form instructions 2018',
'1040ez tax form for 2018': '1040 tax form instructions 2018',
'1040ez tax form': '1040 tax form instructions 2018',
'1040a tax form for 2018': '1040 tax form instructions 2018',
'1040a tax form': '1040 tax form instructions 2018',
'1040ez 2018': '1040 tax form instructions 2018',
'1040 tax forms 2018': '1040 tax form instructions 2018',
'1040 tax forms': '1040 tax form instructions 2018',
'1040 individual tax return 2018': '1040 tax form instructions 2018',
'1040a 2018 tax form': '1040 tax form instructions 2018',
'1040ez 2018 form': '1040 tax form instructions 2018',
'1040 tax table': '1040 tax form instructions 2018',
'1040 form 2018': '1040 tax form instructions 2018',
'1040ez tax forms': '1040 tax form instructions 2018',
'1040 form': '1040 tax form instructions 2018',
'1040 tax form': '1040 tax form instructions 2018',
'1040 tax form 2018': '1040 tax form instructions 2018',
'1040a 2018 tax forms': '1040 tax form instructions 2018',
'1040 instructions 2018': '1040 tax form instructions 2018',
'1040a': '1040 tax form instructions 2018',
'1099-misc tax form': '1099 tax calculator',
'1099 tax form irs': '1099 tax calculator',
'1099 tax form': '1099 tax calculator',
'1099 tax forms 2018': '1099 tax calculator',
'2018 federal tax brackets': '2018 federal income tax forms 1040',
'2018 federal tax form 1040': '2018 federal income tax forms 1040',
'2018 federal tax forms': '2018 federal income tax forms 1040',
'2018 federal tax tables': '2018 federal income tax forms 1040',
'2018 income tax calculator': '2018 federal income tax forms 1040',
'2018 income tax return': '2018 federal income tax forms 1040',
'2018 income tax forms': '2018 federal income tax forms 1040',
'2018 income tax forms 1040': '2018 federal income tax forms 1040',
'2018 income tax brackets': '2018 federal income tax forms 1040',
'2018 irs tax forms': '2018 irs 1040a tax form',
'2018 irs tax tables': '2018 irs 1040a tax form',
'2018 tax w2 form': '2018 tax 1040',
'2018 taxes filed': '2018 tax 1040',
'2018 tax estimator': '2018 tax 1040',
'2018 tax 1099 form': '2018 tax 1040',
'2018 tax information': '2018 tax 1040',
'2018 tax refund schedule': '2018 tax 1040',
'2018 tax forms 1040': '2018 tax 1040',
'2018 tax deductions': '2018 tax 1040',
'2018 tax return': '2018 tax 1040',
'2018 tax forms 1040a': '2018 tax 1040',
'2018 tax law changes': '2018 tax 1040',
'2018 tax forms 1099': '2018 tax 1040',
'2018 tax forms': '2018 tax 1040',
'2018 tax brackets': '2018 tax 1040',
'2018 tax rates': '2018 tax 1040',
'2018 tax forms 1040ez': '2018 tax 1040',
'2018 tax instructions': '2018 tax 1040',
'2018 tax 1040 forms': '2018 tax 1040',
'2018 tax form 1040': '2018 tax 1040',
'2018 tax calculator': '2018 tax 1040',
'2018 tax tables married filing jointly': '2018 tax 1040',
'2018 tax tables': '2018 tax 1040',
'2018 tax 1040 form': '2018 tax 1040',
'2018 tax changes': '2018 tax 1040',
'2018 tax table single': '2018 tax 1040',
'2019 tax preparation for seniors near me': '2019 tax deductions',
'2019 tax tables': '2019 tax deductions',
'2019 income tax brackets': '2019 tax deductions',
'2019 income tax forms': '2019 tax deductions',
'2019 tax calculator': '2019 tax deductions',
'2019 tax refund schedule': '2019 tax deductions',
'2019 tax brackets': '2019 tax deductions',
'2019 federal tax forms': '2019 tax forms 1040',
'2019 1099 tax forms': '2019 tax forms 1040',
'2019 941 quarterly tax form': '2019 tax forms 1040',
'2019 tax forms': '2019 tax forms 1040',
'2019 irs tax refund schedule': '2020 irs tax forms',
'aarp membership': 'aarp life insurance',
'aarp': 'aarp life insurance',
'aarp travel': 'aarp life insurance',
'aarp travel center': 'aarp life insurance',
'myaarpmedicare.com': 'aarp life insurance',
'aarp tax locations': 'aarp life insurance',
'aarp discounts': 'aarp life insurance',
'aarp games': 'aarp life insurance',
'aarp free games': 'aarp life insurance',
'aarp games word wipe': 'aarp life insurance',
'aarp united healthcare': 'aarp life insurance',
'accuweather': 'accuweather.com',
'accuweather radar': 'accuweather.com',
'adp mobile': 'adp payroll',
'adp.com': 'adp payroll',
'adp': 'adp payroll',
'adp run payroll': 'adp payroll',
'adp log in': 'adp payroll',
'adp workforce now': 'adp payroll',
'adp ipay': 'adp payroll',
'adp portal': 'adp payroll',
'adp login': 'adp payroll',
'adp run': 'adp payroll',
'adp vantage': 'adp payroll',
'adp workforce now login': 'adp payroll',
'myadp.com': 'adp payroll',
'www.adp.com': 'adp payroll',
'aesop frontline': 'aesop',
'aesop online': 'aesop',
'aesopfrontline.com': 'aesop',
'aesoponline': 'aesop',
'aesoponline.com': 'aesop',
'aesoponline.com down': 'aesop',
'www.aesoponline.com': 'aesop',
'air bnb': 'air b and b',
'airbnb': 'air b and b',
'airbnb login': 'air b and b',
'alaska airlines check in': 'alaska airlines flights',
'alaska airlines': 'alaska airlines flights',
'alaskan airlines': 'alaska airlines flights',
'www.alaskaair.com': 'alaska airlines flights',
'alaska airlines reservations': 'alaska airlines flights',
'alaska airlines credit card': 'alaska airlines flights',
'allegiant air': 'allegiant airlines',
'allegiant airlines reservations': 'allegiant airlines',
'allegiant airlines destinations': 'allegiant airlines',
'allegiant': 'allegiant airlines',
'amazon books': 'amazon books search',
'amazon customer service phone number': 'amazon customer service',
'amazon phone number': 'amazon customer service',
'amazon customer service number': 'amazon customer service',
'amazon music unlimited': 'amazon music',
'amzn stock': 'amazon stock',
'amazon.com books': 'amazon.com account',
'amazon seller central': 'amazon.com account',
'amazom': 'amazon.com account',
'amazon': 'amazon.com account',
'amazonhoney.com': 'amazon.com account',
'amazon.com': 'amazon.com account',
'www.amazon.com': 'amazon.com account',
'amazon account': 'amazon.com account',
'www.amazon.commytv': 'amazon.com account',
'amazon.comprimesavings': 'amazon.com account',
'amazon prime movies': 'amazonprime.com',
'amazon prime music': 'amazonprime.com',
'amazon prime video': 'amazonprime.com',
'amazon prime': 'amazonprime.com',
'amazon prime movies free': 'amazonprime.com',
'amazon prime login': 'amazonprime.com',
'american express credit card': 'amex',
'american express': 'amex',
'www.americanexpress.com': 'amex',
'amex login': 'amex',
'americanexpress': 'amex',
'american express login': 'amex',
'amtrak reservations': 'amtrak train schedule',
'amtrak map': 'amtrak train schedule',
'amtrak schedule': 'amtrak train schedule',
'amtrak': 'amtrak train schedule',
'animal jam': 'animal jam play wild',
'aol login': 'aol',
'aol.com': 'aol',
'aol sign in': 'aol',
'www.aol.com': 'aol',
'aol.com login': 'aol',
'aol.com sign in': 'aol',
'aol computer checkup': 'aol',
'aol games': 'aol',
'aol news': 'aol',
'aol news sports weather entertainment': 'aol',
'aolcom': 'aol',
'aol email login': 'aol.mail',
'www.aol.commail': 'aol.mail',
'aolmail': 'aol.mail',
'aol email': 'aol.mail',
'aol mail': 'aol.mail',
'www.aolmail.com': 'aol.mail',
'aol mail sign in': 'aol.mail',
'aol verizon email': 'aol.mail',
'aol mail login': 'aol.mail',
'aol.com mail': 'aol.mail',
'aolmail.com': 'aol.mail',
'apple store': 'app store',
'apple airpods': 'app store',
'apple store appointment': 'app store',
'apple.com': 'app store',
'apple support': 'app store',
'apple icloud': 'app store',
'apple itunes': 'app store',
'apple store near me': 'app store',
'www.apple.com': 'app store',
'apple watch': 'apple watch bands',
'apple watch 4': 'apple watch bands',
'att uverse': 'att internet',
'att customer service': 'att internet',
'att': 'att internet',
'www.att.com': 'att internet',
'att.com': 'att internet',
'att.net': 'att internet',
'att bill pay': 'att internet',
'www.att.net': 'att internet',
'att login': 'att internet',
'att wireless': 'att internet',
'att store': 'att internet',
'att.net yahoo': 'att internet',
'att.yahoo.com': 'att internet',
'att email login': 'att yahoo mail',
'att.net email': 'att yahoo mail',
'att email': 'att yahoo mail',
'bankofamerica login': 'bank of america credit card',
'www.bankofamerica.com': 'bank of america credit card',
'bankofamerica.com': 'bank of america credit card',
'bank of america online': 'bank of america credit card',
'bank of america sign in': 'bank of america credit card',
'httpswww.bankofamerica.com': 'bank of america credit card',
'bank of america': 'bank of america credit card',
'bank of america online banking sign in': 'bank of america credit card',
'bankofamerica': 'bank of america credit card',
'bank of america online banking': 'bank of america credit card',
'bank of america login': 'bank of america credit card',
'bankofamericaonlinebanking': 'bank of america credit card',
'www.bankofamerica.com onlinebanking': 'bank of america credit card',
'bank of america edd': 'bank of america credit card',
'bank of america near me': 'bank of america credit card',
'bank of american fork': 'bank of america credit card',
'www.bbt.com': 'bbt',
'bbt bank': 'bbt',
'bbt online banking': 'bbt',
'bbt.comu': 'bbt',
'bed bath and beyond registry': 'bed bath and beyond coupon',
'www.bedbathandbeyond.com': 'bed bath and beyond coupon',
'bed bath and beyond': 'bed bath and beyond coupon',
'bed bath beyond': 'bed bath and beyond coupon',
'bedbathandbeyond': 'bed bath and beyond coupon',
'best free tax calulator 2019': 'best free tax filing 2019',
'best online tax filing': 'best free tax filing 2019',
'best tax software 2019': 'best free tax filing 2019',
'best price for turbotax deluxe 2018': 'best free tax filing 2019',
'best buy near me': 'bestbuy',
'best buy hours': 'bestbuy',
'best buy': 'bestbuy',
'best buy credit card': 'bestbuy',
'www.bestbuy.com': 'bestbuy',
'bing': 'bing maps',
'bing images': 'bing maps',
'bing news': 'bing maps',
'www.bing.com': 'bing maps',
'business tax calculator': 'business tax software 2018',
'calculator google': 'calculator app',
'calculator online': 'calculator app',
'calculator': 'calculator app',
'calculator soup': 'calculator app',
'capital one auto finance': 'capital one quicksilver',
'capital one credit card': 'capital one quicksilver',
'capital one auto': 'capital one quicksilver',
'www.capitalone.com': 'capital one quicksilver',
'capitalonebank.com': 'capital one quicksilver',
'capital one 360': 'capital one quicksilver',
'capital one credit card payment': 'capital one quicksilver',
'capital one online banking': 'capital one quicksilver',
'capital one': 'capital one quicksilver',
'capital one online': 'capital one quicksilver',
'capitalone.comactivate': 'capital one quicksilver',
'capitalone.comactivate card': 'capital one quicksilver',
'capital one login': 'capital one quicksilver',
'capital one bank': 'capital one quicksilver',
'capital one credit card login': 'capital one quicksilver',
'capitalone.com': 'capital one quicksilver',
'car gurus': 'cargurus used cars',
'cargurus': 'cargurus used cars',
'car guru': 'cargurus used cars',
'cbs all access': 'cbs',
'cbsnews': 'cbs',
'cbs news': 'cbs',
'cbs sports': 'cbs',
'cbssports': 'cbs',
'www.chase.comamazon': 'chase amazon',
'chase bank': 'chase bank online',
'chase bank locations': 'chase bank online',
'chase banking online': 'chase bank online',
'chasebankonline': 'chase bank online',
'chase card services': 'chase credit card',
'chase credit card login': 'chase credit card',
'chase online': 'chase online banking',
'chase online logon': 'chase online banking',
'chase.comamazon': 'chase visa',
'chase.comincreasemyline': 'chase visa',
'chase official site': 'chase visa',
'chase mortgage': 'chase visa',
'www.chase.comunited': 'chase visa',
'chase': 'chase visa',
'www.chase.comverifycard': 'chase visa',
'www.chase.com': 'chase visa',
'chase log in': 'chase visa',
'chase.com': 'chase visa',
'chase.com sign in': 'chase visa',
'chase login': 'chase visa',
'chase logon': 'chase visa',
'chase.comverifycard': 'chase visa',
'cheap tickets flights': 'cheap flights',
'cheap tickets': 'cheap flights',
'cheaptickets': 'cheap flights',
'chrome web store apps': 'chrome web store',
'chrome': 'chrome web store',
'citi.com': 'citi costco',
'www.citi.com': 'citi costco',
'www.citibank.com': 'citibank.com',
'citibank credit card': 'citibank.com',
'citibank': 'citibank.com',
'citi bank': 'citibank.com',
'citibank sign on': 'citibank.com',
'citibank costco': 'citibank.com',
'citibank sign in': 'citibank.com',
'citibank online': 'citibank.com',
'www.citicards.com': 'citicards.com',
'citicards': 'citicards.com',
'citicards sign in': 'citicards.com',
'citizensbankonline': 'citizen bank',
'citizens bank online': 'citizen bank',
'www.citizensbank.com': 'citizen bank',
'citizensbank.com': 'citizen bank',
'citizens bank': 'citizen bank',
'citizensbank': 'citizen bank',
'citizens national bank': 'citizen bank',
'clever login': 'clever',
'clever portal': 'clever',
'www.cnn.com': 'cnn live stream',
'cnn news': 'cnn live stream',
'cnn live today': 'cnn live stream',
'cnn live': 'cnn live stream',
'cnn world news': 'cnn live stream',
'cnn breaking news': 'cnn live stream',
'cnn': 'cnn live stream',
'cnn 10': 'cnn live stream',
'cnn money': 'cnn live stream',
'cnn.com': 'cnn live stream',
'cnn10': 'cnn live stream',
'comcast.net email': 'comcast.com',
'comcast speed test': 'comcast.com',
'comcast xfinity': 'comcast.com',
'comcast': 'comcast.com',
'comcast.net': 'comcast.com',
'www.comcast.net': 'comcast.com',
'comcast email': 'comcast.com',
'comcast bill pay': 'comcast.com',
'www.cox.net': 'cox communications',
'cox cable': 'cox communications',
'cox customer service': 'cox communications',
'cox email': 'cox communications',
'cox.net webmail': 'cox communications',
'cox.net': 'cox communications',
'cox': 'cox communications',
'cox webmail': 'cox communications',
'cox.net email': 'cox communications',
'www.cox.commybill': 'cox communications',
'credit karma tax': 'creditkarma',
'creditkarma tax': 'creditkarma',
'www.creditkarma.com': 'creditkarma',
'credit karma free': 'creditkarma',
'credit karma': 'creditkarma',
'credit karma login': 'creditkarma',
'httpswww.creditonebank.com': 'creditone.com',
'www.creditonebank.com': 'creditone.com',
'creditone': 'creditone.com',
'credit one': 'creditone.com',
'creditonebank': 'creditone.com',
'creditone bank': 'creditone.com',
'credit one bank': 'creditone.com',
'delta airlines tickets': 'delta vacations',
'delta flight status': 'delta vacations',
'delta airlines': 'delta vacations',
'www.delta.com': 'delta vacations',
'delta check in': 'delta vacations',
'delta': 'delta vacations',
'delta.com': 'delta vacations',
'delta airlines check in': 'delta vacations',
'dictionary': 'dictionary webster',
'dictionary.com': 'dictionary webster',
'directv now': 'directv packages',
'directv customer service': 'directv packages',
'direct tv': 'directv packages',
'directv': 'directv packages',
'directv sign in': 'directv packages',
'www.dmv.ca.gov': 'dmv appointment',
'dmvnv.com': 'dmv appointment',
'dmv.ca.gov': 'dmv appointment',
'dmv pa': 'dmv appointment',
'dmv renewal': 'dmv appointment',
'www.dmvnow.com': 'dmv appointment',
'dmv ct': 'dmv appointment',
'dmv.ca.gov online': 'dmv appointment',
'dmv appointments': 'dmv appointment',
'dmv.org': 'dmv appointment',
'dmv': 'dmv appointment',
'dmv practice test': 'dmv appointment',
'dmv.ny.gov': 'dmv appointment',
'dmv near me': 'dmv appointment',
'www.dmv.ny.gov': 'dmv appointment',
'dmv.dc.gov': 'dmv appointment',
'dmv ny': 'dmv appointment',
'dow jones today': 'dow jones live update',
'dow jones': 'dow jones live update',
'drudgereport': 'drudge report',
'drudge': 'drudge report',
'earned income tax credit 2018': 'earned income tax table 2018',
'ebay coupon': 'ebay account',
'ebay store': 'ebay account',
'ebay login': 'ebay account',
'ebay.com my ebay': 'ebay account',
'ebay motors': 'ebay account',
'ebay sign in': 'ebay account',
'ebay': 'ebay account',
'ebay.com': 'ebay account',
'www.ebay.com': 'ebay account',
'my ebay': 'ebay account',
'ebay customer service': 'ebay account',
'ebay official site': 'ebay account',
'ebay stock': 'ebay account',
'efile 2017 taxes free': 'e-file taxes',
'e file taxes': 'e-file taxes',
'e file taxes 2019': 'e-file taxes',
'efile taxes': 'e-file taxes',
'e file tax return': 'e-file taxes',
'e tax': 'e-file taxes',
'email address': 'email sign up',
'www.email.com': 'email sign up',
'my email': 'email sign up',
'email account': 'email sign up',
'email': 'email sign up',
'emails': 'email sign up',
'email.secureserver.net': 'email sign up',
'email.com': 'email sign up',
'email comcast': 'email sign up',
'espn plus': 'espn3',
'espn nfl': 'espn3',
'espn news': 'espn3',
'www.espn.com': 'espn3',
'espn nba': 'espn3',
'espn': 'espn3',
'espn fantasy': 'espn3',
'espn fantasy football': 'espn3',
'espn radio': 'espn3',
'estimate tax refund 2018': 'estimate taxes',
'estimated tax return 2019': 'estimate taxes',
'estimated tax return': 'estimate taxes',
'estimate tax return': 'estimate taxes',
'estimate tax refund': 'estimate taxes',
'estimate 2019 tax return': 'estimate taxes',
'etax.com login': 'etax.com',
'etax login': 'etax.com',
'etrade': 'etrade tax documents',
'etsy.com': 'etsy',
'www.etsy.com': 'etsy',
'expedia hotel': 'expedia flight',
'expedia car rental': 'expedia flight',
'expedia hotels': 'expedia flight',
'expedia vacation packages': 'expedia flight',
'expedia flights': 'expedia flight',
'expedia.com': 'expedia flight',
'expedia': 'expedia flight',
'expedia.com flights': 'expedia flight',
'express tax refund': 'expresstaxrefund.com',
'eztaxreturn coupon': 'ez tax return 2018',
'eztaxreturn.com': 'ez tax return 2018',
'eztaxreturn': 'ez tax return 2018',
'ez tax return': 'ez tax return 2018',
'facebook': 'facebook app',
'facebook log in': 'facebook app',
'facebook portal': 'facebook app',
'facebook messenger': 'facebook app',
'facebook com login': 'facebook app',
'facebook login': 'facebook app',
'www.facebook.com': 'facebook app',
'face': 'facebook app',
'face book': 'facebook app',
'facebook account': 'facebook app',
'facebook face book': 'facebook app',
'facebook facebook': 'facebook app',
'facebook log in to facebook': 'facebook app',
'facebook login page': 'facebook app',
'facebook logo': 'facebook app',
'facebook search': 'facebook app',
'facebook stock': 'facebook app',
'facebook.com': 'facebook app',
'facebooklogin': 'facebook app',
'www.facebook.com login': 'facebook app',
'federal income tax forms 2019': 'federal income tax withheld',
'federal income tax calculator 2018': 'federal income tax withheld',
'federal income tax forms 2018': 'federal income tax withheld',
'federal income tax rate': 'federal income tax withheld',
'federal income tax calculator': 'federal income tax withheld',
'federal income tax rate 2018': 'federal income tax withheld',
'federal income tax brackets 2018': 'federal income tax withheld',
'federal tax return free': 'federal tax form for 2018',
'federal tax id application': 'federal tax form for 2018',
'federal tax rate 2018': 'federal tax form for 2018',
'federal tax calculator': 'federal tax form for 2018',
'fed income tax forms 2018': 'federal tax form for 2018',
'fed tax forms 1040': 'federal tax form for 2018',
'federal tax form 1040': 'federal tax form for 2018',
'federal tax return': 'federal tax form for 2018',
'federal tax return 2019': 'federal tax form for 2018',
'federal tax forms': 'federal tax form for 2018',
'federal tax forms for 2018': 'federal tax form for 2018',
'federal tax brackets 2018': 'federal tax form for 2018',
'federal tax form 1040 for 2018': 'federal tax form for 2018',
'federal tax forms 2018': 'federal tax form for 2018',
'federal tax tables 2018': 'federal tax form for 2018',
'fed tax forms': 'federal tax form for 2018',
'federal taxes 2018': 'federal tax form for 2018',
'federal tax forms 2019': 'federal tax form for 2018',
'federal tax brackets': 'federal tax form for 2018',
'federal tax brackets 2019': 'federal tax form for 2018',
'fedex tracking': 'fedex ground',
'federal express tracking': 'fedex ground',
'fedex shipping': 'fedex ground',
'fedex near me': 'fedex ground',
'www.fedex.com': 'fedex ground',
'fedex.com': 'fedex ground',
'fedex freight': 'fedex ground',
'fedex login': 'fedex ground',
'fed ex': 'fedex ground',
'fedex': 'fedex ground',
'federal express': 'fedex ground',
'fedex customer service': 'fedex ground',
'fidelity 401k': 'fidelity net benefits',
'fidelity investments': 'fidelity net benefits',
'www.fidelity.com': 'fidelity net benefits',
'fidelity': 'fidelity net benefits',
'fidelity log in': 'fidelity net benefits',
'fidelity.com': 'fidelity net benefits',
'fidelity netbenefits': 'fidelity net benefits',
'fidelity login': 'fidelity net benefits',
'file taxes for free 2019': 'file taxes for free',
'file free tax': 'file taxes for free',
'file taxes free': 'file taxes for free',
'filing taxes online': 'file taxes for free',
'filing taxes 2019': 'file taxes for free',
'file federal taxes online': 'file taxes for free',
'file taxes online': 'file taxes for free',
'file my taxes': 'file taxes for free',
'file taxes online free': 'file taxes for free',
'filing taxes': 'file taxes for free',
'filing taxes 2018': 'file taxes for free',
'file 2018 taxes online': 'file taxes for free',
'file my taxes 2018': 'file taxes for free',
'file taxes': 'file taxes for free',
'file 2017 taxes online': 'file taxes for free',
'file employer taxes online': 'file taxes for free',
'file taxes 2019': 'file taxes for free',
'file income tax 2019': 'file taxes for free',
'fortnite game': 'fortnite free download',
'fortnite': 'fortnite free download',
'fortnite download': 'fortnite free download',
'fortnite account generator': 'fortnite free download',
'fortnite.com': 'fortnite free download',
'fortnite item shop': 'fortnite free download',
'fortnite tracker': 'fortnite free download',
'fortnite on scratch': 'fortnite free download',
'fortnite season 7': 'fortnite free download',
'fortnite coloring pages': 'fortnite free download',
'fortnite skins': 'fortnite free download',
'fortnite pictures': 'fortnite free download',
'fox news': 'fox news live',
'www.foxnews.com': 'fox news live',
'fox news live stream': 'fox news live',
'fox 2 news': 'fox news live',
'fox 8 news': 'fox news live',
'foxnews': 'fox news live',
'fox breaking news': 'fox news live',
'fox news official site': 'fox news live',
'free online taxes': 'free tax file',
'free online tax filing 2019': 'free tax file',
'free tax preparation online': 'free tax file',
'free online tax filing': 'free tax file',
'free tax return 2019': 'free tax file',
'free federal and state tax filing': 'free tax file',
'free state and federal tax filing': 'free tax file',
'free tax filing': 'free tax file',
'free credit report': 'free tax file',
'free pa state tax filing': 'free tax file',
'free tax filing 2019': 'free tax file',
'free tax return': 'free tax file',
'free tax act': 'free tax file',
'free tax preparation 2019': 'free tax file',
'free taxact 2018': 'free tax file',
'free income tax filing': 'free tax file',
'free taxes online': 'free tax file',
'free income tax': 'free tax file',
'free taxes': 'free tax file',
'free tax returns for senior citizens': 'free tax file',
'free irs tax filing': 'free tax file',
'free federal tax filing': 'free tax file',
'free tax software 2019': 'free tax file',
'free online tax calculator': 'free tax file',
'free tax filing for low income': 'free tax file',
'free federal and mn tax e file': 'free tax file',
'free taxes 2018': 'free tax file',
'free fillable tax forms 2018': 'free tax file',
'free tax filing sites': 'free tax file',
'free tax services': 'free tax file',
'free filing taxes': 'free tax file',
'free income tax preparation': 'free tax file',
'free 1040 tax return': 'free tax file',
'free tax software 2018': 'free tax file',
'free tax forms 2018': 'free tax file',
'free tax usa 2019': 'free tax file',
'free usa tax': 'free tax file',
'free aarp tax preparation near me': 'free tax file',
'free 1099 tax forms': 'free tax file',
'free income tax calculator': 'free tax file',
'free income tax service': 'free tax file',
'free tax': 'free tax file',
'free tax sites': 'free tax file',
'free tax calculator 2019': 'free tax file',
'free tax calculator': 'free tax file',
'free state tax filing 2019': 'free tax file',
'freetax.com': 'free tax file',
'free tax filing 2017': 'free tax file',
'freetax 2017': 'free tax file',
'free tax usa': 'free tax file',
'freetax': 'free tax file',
'free tax preparation': 'free tax file',
'free 1099 tax form': 'free tax file',
'freetaxusa reviews': 'freetaxusa.com',
'httpswww.freetaxusa.com': 'freetaxusa.com',
'freetaxusa': 'freetaxusa.com',
'freetaxusa 2019': 'freetaxusa.com',
'freetaxusa 2018 sign in': 'freetaxusa.com',
'freetaxusa 2015': 'freetaxusa.com',
'freetaxusa 2018': 'freetaxusa.com',
'freetaxusa coupon code': 'freetaxusa.com',
'freetaxusa coupon': 'freetaxusa.com',
'freetaxusa 2016': 'freetaxusa.com',
'friv4school': 'friv 2',
'friv games': 'friv 2',
'www.friv.com': 'friv 2',
'friv.com': 'friv 2',
'friv': 'friv 2',
'friv 5': 'friv 2',
'frontier airlines deals': 'frontier.com',
'frontier internet': 'frontier.com',
'frontier airlines': 'frontier.com',
'www.frontierairlines.com': 'frontier.com',
'google calendar app': 'google calendar 2019',
'google calendar': 'google calendar 2019',
'google chrome update': 'google chrome store',
'google chrome extensions': 'google chrome store',
'google chrome': 'google chrome store',
'google classroom sign': 'google classroom',
'google docs offline': 'google documents',
'google docs sign in': 'google documents',
'google docs drive': 'google documents',
'google doc': 'google documents',
'google docs': 'google documents',
'googledocs': 'google documents',
'google drive': 'google drive sign in',
'google drive download': 'google drive sign in',
'google earth pro': 'google earth view',
'google earth': 'google earth view',
'google home app': 'google home',
'google gmail': 'google mail sign in',
'google mail': 'google mail sign in',
'google mail account': 'google mail sign in',
'google email': 'google mail sign in',
'google gmail sign in gmail login': 'google mail sign in',
'google maps satellite': 'google map directions',
'google maps street view': 'google map directions',
'googlemaps': 'google map directions',
'google maps driving directions': 'google map directions',
'google maps': 'google map directions',
'google maps.com': 'google map directions',
'googlemaps.com': 'google map directions',
'google.com maps': 'google map directions',
'google map': 'google map directions',
'google play': 'google play app',
'google play store app': 'google play app',
'google play store': 'google play app',
'google play music': 'google play app',
'www.googlemaps.com': 'google sites',
'my google drive': 'google sites',
'google sign in': 'google sites',
'google express': 'google sites',
'google search': 'google sites',
'google space': 'google sites',
'google hangouts': 'google sites',
'google homepage': 'google sites',
'google books': 'google sites',
'www.google.com': 'google sites',
'google': 'google sites',
'google.com': 'google sites',
'google accounts': 'google sites',
'google news': 'google sites',
'google search console': 'google sites',
'google login': 'google sites',
'google voice': 'google sites',
'google slides themes': 'google sites',
'google images': 'google sites',
'google slides': 'google sites',
'google scholar': 'google sites',
'goog': 'google sites',
'googe': 'google sites',
'googel': 'google sites',
'googl': 'google sites',
'google account': 'google sites',
'google analytics': 'google sites',
'google apps': 'google sites',
'google business': 'google sites',
'google calculator': 'google sites',
'google contacts': 'google sites',
'google feud': 'google sites',
'google finance': 'google sites',
'google games': 'google sites',
'google gravity': 'google sites',
'google image search': 'google sites',
'google pictures': 'google sites',
'google search engine': 'google sites',
'google.com flights': 'google sites',
'google': 'google sites',
'googlecom': 'google sites',
'googlr': 'google sites',
'goole': 'google sites',
'goolge': 'google sites',
'https://www.google.com': 'google sites',
'google translate': 'google translate spanish to english',
'google translator': 'google translate spanish to english',
'home depot': 'home depot coupons',
'home depot near me': 'home depot coupons',
'homedepot': 'home depot coupons',
'home depot hours': 'home depot coupons',
'hotmail sign in': 'hotmail.com login',
'www.hotmail.com': 'hotmail.com login',
'hotmail.com': 'hotmail.com login',
'hotmail': 'hotmail.com login',
'hotmail login': 'hotmail.com login',
'hotmail inbox': 'hotmail.com login',
'how to file your own taxes': 'how to file taxes for free',
'how to file taxes online': 'how to file taxes for free',
'how to file 2018 taxes': 'how to file taxes for free',
'how to file taxes': 'how to file taxes for free',
'h  r block tax estimator': 'hr block free',
'hr block tax calculator': 'hr block free',
'hr block free file 2019': 'hr block free',
'hr block tax software': 'hr block free',
'hr block tax estimator': 'hr block free',
'hrblock taxes': 'hr block free',
'h and r block free tax filing': 'hr block free',
'hr block taxes online': 'hr block free',
'h and r block taxes online': 'hr block free',
'h r block tax software': 'hr block free',
'hr block free tax filing': 'hr block free',
'h and r block tax calculator': 'hr block free',
'h and r block tax estimator': 'hr block free',
'hr 2017 tax return': 'hr block free',
'h  r block tax software 2019': 'hr block free',
'hr block 2018 tax software': 'hr block free',
'h  r block tax software': 'hr block free',
'h and r block tax advance': 'hr block free',
'h  r block taxes': 'hr block free',
'h and r block tax software': 'hr block free',
'hr block': 'hr block free',
'hrblock sign in': 'hr block free',
'h  r block': 'hr block free',
'hr block tax return': 'hr block free',
'h and r block': 'hr block free',
'hr taxes': 'hr block free',
'hr block taxes': 'hr block free',
'huffington post news': 'huffington post',
'huffpost': 'huffington post',
'hulu live tv': 'hulu plans',
'hulu tv': 'hulu plans',
'hulu live': 'hulu plans',
'hulu movies': 'hulu plans',
'hulu account': 'hulu plans',
'www.hulu.comactivate': 'hulu plans',
'hulu shows': 'hulu plans',
'hulu': 'hulu plans',
'icloud email': 'icloud.com',
'www.icloud.com': 'icloud.com',
'httpswww.icloud.com': 'icloud.com',
'icloud photos': 'icloud.com',
'icloud': 'icloud.com',
'icloud mail': 'icloud.com',
'income tax calculator 2019': 'income tax credit',
'income tax 2019': 'income tax credit',
'income tax': 'income tax credit',
'income taxes': 'income tax credit',
'income tax refund calculator': 'income tax credit',
'income tax estimator': 'income tax credit',
'income tax software online home sales': 'income tax credit',
'income tax return': 'income tax credit',
'income tax forms': 'income tax credit',
'income tax calculator': 'income tax credit',
'income tax forms 2019': 'income tax credit',
'income tax brackets': 'income tax credit',
'income tax forms 2018': 'income tax credit',
'income tax refund schedule 2019': 'income tax credit',
'indeed jobs': 'indeed resume',
'indeed.com': 'indeed resume',
'indeed': 'indeed resume',
'indeed login': 'indeed resume',
'indeed nj': 'indeed resume',
'www.indeed.com': 'indeed resume',
'instagram search': 'instagram',
'instagram login': 'instagram',
'instagram.com': 'instagram',
'iready': 'i-ready math',
'i-ready': 'i-ready math',
'irs income tax 2019': 'irs free tax filing',
'irs 2017 tax return': 'irs free tax filing',
'irs free tax preparation': 'irs free tax filing',
'irs form 1040': 'irs free tax filing',
'www.irs.gov': 'irs free tax filing',
'httpswww.irs.gov': 'irs free tax filing',
'irs form 1040 for 2018 tax filing': 'irs free tax filing',
'irs.gov': 'irs free tax filing',
'irs.gov tax forms': 'irs free tax filing',
'irs.gov transcript': 'irs free tax filing',
'irs 2018 tax forms': 'irs free tax filing',
'irs mileage rate 2019': 'irs free tax filing',
'irs forms 2019': 'irs free tax filing',
'irs.gov forms': 'irs free tax filing',
'irs forms': 'irs free tax filing',
'irs': 'irs free tax filing',
'irs payment': 'irs free tax filing',
'irs 2018 tax tables': 'irs free tax filing',
'irs customer service': 'irs free tax filing',
'irs tax form 1040a': 'irs tax form 1040ez 2018',
'irs tax form 990': 'irs tax form 1040ez 2018',
'irs tax forms and instructions 2018': 'irs tax form 1040ez 2018',
'irs tax form 1099': 'irs tax form 1040ez 2018',
'irs tax form 1040 for 2018': 'irs tax form 1040ez 2018',
'irs tax forms': 'irs tax form 1040ez 2018',
'irs tax forms 1040': 'irs tax form 1040ez 2018',
'irs tax forms for 2018': 'irs tax form 1040ez 2018',
'irs tax forms 2019': 'irs tax form 1040ez 2018',
'irs tax form 1040': 'irs tax form 1040ez 2018',
'irs tax forms 2018': 'irs tax form 1040ez 2018',
'irs tax filing': 'irs tax return',
'irs tax id number': 'irs tax return',
'irs tax id': 'irs tax return',
'irs tax .gov': 'irs tax return',
'irs tax brackets': 'irs tax return',
'irs tax identification number': 'irs tax return',
'irs tax calculator': 'irs tax return',
'irs tax refund schedule 2019': 'irs tax return',
'irs tax tables for 2018': 'irs tax tables 2019',
'irs tax tables': 'irs tax tables 2019',
'irs tax tables 2018': 'irs tax tables 2019',
'itunes download': 'itunes.com',
'itunes': 'itunes.com',
'itunes account': 'itunes.com',
'jcpenny': 'jcpenneys',
'jcp': 'jcpenneys',
'jcp.comcredit': 'jcpenneys',
'jcpenney coupons': 'jcpenneys',
'jc penneys coupons': 'jcpenneys',
'jcpenney': 'jcpenneys',
'www.jcpenney.com': 'jcpenneys',
'jcps': 'jcpenneys',
'jc penny': 'jcpenneys',
'jcpenney credit card': 'jcpenneys',
'jcpenney.com': 'jcpenneys',
'jetblue vacations': 'jetblue airlines flights',
'jetblue credit card': 'jetblue airlines flights',
'jetblue flights': 'jetblue airlines flights',
'jet blue': 'jetblue airlines flights',
'jetblue': 'jetblue airlines flights',
'jet blue vacations': 'jetblue airlines flights',
'jetblue flight status': 'jetblue airlines flights',
'www.jetblue.com': 'jetblue airlines flights',
'jetblue check in': 'jetblue airlines flights',
'kahoot.com': 'kahoot create',
'kahoot it': 'kahoot create',
'kahoot': 'kahoot create',
'kahoots': 'kahoot create',
'kayak flight': 'kayak hotels',
'kayak flights': 'kayak hotels',
'kayak': 'kayak hotels',
'kayak.com': 'kayak hotels',
'www.kayak.com': 'kayak hotels',
'kelley blue book car values': 'kelly blue book car values',
'kelley blue book car value': 'kelly blue book car values',
'kelley blue book used cars': 'kelly blue book car values',
'kbb car value': 'kelly blue book car values',
'kelley blue book': 'kelly blue book car values',
'kelley blue book value': 'kelly blue book car values',
'kbb': 'kelly blue book car values',
'kelly blue book': 'kelly blue book car values',
'kbb.com': 'kelly blue book car values',
'keybank online': 'key bank',
'keybank': 'key bank',
'key bank login': 'key bank',
'keybank.com': 'key bank',
'www.key.com': 'key bank',
'khan': 'khan academy',
'kroger.com': 'krogers',
'kroger weekly ad': 'krogers',
'kroger ad': 'krogers',
'kroger': 'krogers',
'kroger digital coupons': 'krogers',
'krogerfeedback': 'krogers',
'www.krogerfeedback.com': 'krogers',
'liberty tax online': 'libertytax.com login',
'liberty tax': 'libertytax.com login',
'linkedin': 'linkedin jobs',
'linked in': 'linkedin jobs',
'linkedin login': 'linkedin jobs',
'lowes appliances': 'lowes coupon',
'lowes careers': 'lowes coupon',
'lowes credit card': 'lowes coupon',
'lowes': 'lowes coupon',
'lowes hours': 'lowes coupon',
'www.lowes.comsurvey': 'lowes coupon',
'www.lowes.com': 'lowes coupon',
'lowes home improvement': 'lowes coupon',
'macys.com coupon': 'macys coupon',
'macys furniture': 'macys coupon',
'macys': 'macys coupon',
'macys hours': 'macys coupon',
'macy': 'macys coupon',
'www.macys.com': 'macys coupon',
'macys credit card': 'macys coupon',
'macys insite': 'macys coupon',
'mahjong': 'mahjong games',
'mahjongg dimensions': 'mahjong games',
'mahjong connect': 'mahjong games',
'mahjongg': 'mahjong games',
'mahjong 247': 'mahjong games',
'mail': 'mailbox',
'www.mail.yahoo.com': 'mailbox',
'mail.com': 'mailbox',
'mail.aol.com': 'mailbox',
'mail.yahoo': 'mailbox',
'mail.twc.com': 'mailbox',
'mail yahoo': 'mailbox',
'mail.aol': 'mailbox',
'mail.google.com': 'mailbox',
'mail.yahoo.com': 'mailbox',
'www.mail.aol.com': 'mailbox',
'www.mail.com': 'mailbox',
'www.mapquest.com': 'mapquest route planner',
'map quest': 'mapquest route planner',
'map questions directions': 'mapquest route planner',
'mapquest mileage': 'mapquest route planner',
'mapquest directions': 'mapquest route planner',
'mapquest driving directions': 'mapquest route planner',
'mapquest': 'mapquest route planner',
'mapquest.com': 'mapquest route planner',
'mapquest official site': 'mapquest route planner',
'maps street view': 'maps driving directions',
'maps': 'maps driving directions',
'www.menards.com': 'menards',
'menards rebates': 'menards',
'menards hours': 'menards',
'microsoft 365': 'microsoft account',
'microsoft': 'microsoft account',
'microsoft store': 'microsoft account',
'microsoft 365 login': 'microsoft account',
'microsoft office 365': 'microsoft office',
'microsoft word': 'microsoft word free',
'microsoft word online': 'microsoft word free',
'minecraft games': 'minecraft download',
'minecraft education edition': 'minecraft download',
'minecraft unblocked': 'minecraft download',
'minecraft free': 'minecraft download',
'minecraft': 'minecraft download',
'minecraft servers': 'minecraft download',
'minecraft mods': 'minecraft download',
'minecraft skins': 'minecraft download',
'mobymax': 'mobymax games',
'moby max': 'mobymax games',
'mobymax.com': 'mobymax games',
'msnbc.com': 'msnbc live',
'msnbc news': 'msnbc live',
'msnbc live streaming': 'msnbc live',
'msnbc': 'msnbc live',
'gmail sign up': 'my gmail account',
'gmail signup': 'my gmail account',
'gmail sign in': 'my gmail account',
'gmail account': 'my gmail account',
'www.gmail.com': 'my gmail account',
'gmail account login': 'my gmail account',
'gmailcom': 'my gmail account',
'gmail.com': 'my gmail account',
'gmail login mail': 'my gmail account',
'www.gmail.com sign in': 'my gmail account',
'gmail login': 'my gmail account',
'gmail inbox': 'my gmail account',
'gmail': 'my gmail account',
'gmail.com sign in': 'my gmail account',
'my gmail': 'my gmail account',
'gmail sign in mail': 'my gmail account',
'myatt.net': 'myatt',
'myatt.com': 'myatt',
'my att': 'myatt',
'myatt.att.com': 'myatt',
'nbc live': 'nbc news',
'nbc': 'nbc news',
'nbc 29 news': 'nbc news',
'nbc schedule': 'nbc news',
'nbc sports': 'nbc news',
'nbc sports gold': 'nbc news',
'nbcnews': 'nbc news',
'netflix series': 'netflix movies',
'netflix.com': 'netflix movies',
'netflix shows': 'netflix movies',
'www.netflix.com': 'netflix movies',
'netflix': 'netflix movies',
'netflix sign in': 'netflix movies',
'netflix account': 'netflix movies',
'netflex': 'netflix movies',
'netflix originals': 'netflix movies',
'new tax brackets 2018': 'new tax deductions for 2019',
'new tax laws for 2019 filing': 'new tax deductions for 2019',
'newspapers': 'newsday',
'news today': 'newsday',
'news channel 13': 'newsday',
'news': 'newsday',
'newsela': 'newsday',
'news 12': 'newsday',
'news gazette': 'newsday',
'news 12 long island': 'newsday',
'news and observer': 'newsday',
'news google': 'newsday',
'news2you': 'newsday',
'news4jax': 'newsday',
'nordstrom': 'nordstrom rack',
'nordstrom.com': 'nordstrom rack',
'nordstroms': 'nordstrom rack',
'office 365 outlook login': 'office 365 login',
'www.office365.com': 'office 365 login',
'office365': 'office 365 login',
'office 365': 'office 365 login',
'office 365 sign in': 'office 365 login',
'office depot business': 'office depot near me',
'office depot': 'office depot near me',
'online tax filing': 'optima tax relief',
'online taxes free': 'optima tax relief',
'online tax preparation': 'optima tax relief',
'online tax preparation course': 'optima tax relief',
'online tax prep that can download my 2017 tax return': 'optima tax relief',
'online tax services': 'optima tax relief',
'optimum': 'optimum online',
'optimum login': 'optimum online',
'optonline': 'optimum online',
'optimum.net': 'optimum online',
'optimum sign in': 'optimum online',
'optimum webmail': 'optimum online',
'outlookmail': 'outlook account',
'outlook mail': 'outlook account',
'outlook.com': 'outlook account',
'outlook sign in': 'outlook account',
'outlook email': 'outlook account',
'outlook 365 sign in': 'outlook account',
'www.outlook.com': 'outlook account',
'outlook 365': 'outlook account',
'outlook': 'outlook account',
'outlook365': 'outlook account',
'outube': 'outlook account',
'pandora music': 'pandora radio stations',
'pandora': 'pandora radio stations',
'pandora radio login': 'pandora radio stations',
'pandora free music': 'pandora radio stations',
'pandora app': 'pandora radio stations',
'www.pandora.com': 'pandora radio stations',
'pandora sign in': 'pandora radio stations',
'pandora login': 'pandora radio stations',
'pandora music website': 'pandora radio stations',
'pandora radio': 'pandora radio stations',
'pandora.com': 'pandora radio stations',
'paypal credit': 'paypal customer service',
'paypal sign up': 'paypal customer service',
'paypal account': 'paypal customer service',
'paypal sign in': 'paypal customer service',
'www.paypal.com': 'paypal customer service',
'paypal.com': 'paypal customer service',
'paypal': 'paypal customer service',
'paypal login': 'paypal customer service',
'payroll tax calculator': 'payroll tax',
'payroll taxes': 'payroll tax',
'pbs kids shows': 'pbskids games',
'pbs': 'pbskids games',
'pbs kids games': 'pbskids games',
'pbs kids': 'pbskids games',
'pbskids': 'pbskids games',
'pbs kids videos': 'pbskids games',
'pinterest food': 'pinterest everything',
'pinterest ideas': 'pinterest everything',
'pinterest app': 'pinterest everything',
'pinterest': 'pinterest everything',
'pinterest crafts': 'pinterest everything',
'pintrest': 'pinterest everything',
'pinterest.com': 'pinterest everything',
'www.pinterest.com': 'pinterest everything',
'pnc bank locations': 'pnc mortgage',
'pnc personal banking': 'pnc mortgage',
'pnc virtual wallet': 'pnc mortgage',
'pnc banking': 'pnc mortgage',
'www.pnc.com': 'pnc mortgage',
'www.pncbank.com online banking': 'pnc mortgage',
'pnc bank': 'pnc mortgage',
'pnc.com': 'pnc mortgage',
'pnc': 'pnc mortgage',
'pnc bank login': 'pnc mortgage',
'pncbank.com': 'pnc mortgage',
'pnc careers': 'pnc mortgage',
'pnc online banking': 'pnc mortgage',
'www.pncbank.com': 'pnc mortgage',
'pof': 'pof dating site',
'pof account logon': 'pof dating site',
'www.pof.com': 'pof dating site',
'www.pogo.com': 'pogo',
'pogo games': 'pogo',
'pogo scrabble': 'pogo',
'property tax': 'property taxes',
'qvc': 'qvc.com',
'qvc live': 'qvc.com',
'refund tax 2019': 'refund tax calculator',
'regions bank login': 'regions online banking',
'regions': 'regions online banking',
'regions bank': 'regions online banking',
'regions.com': 'regions online banking',
'www.regions.com': 'regions online banking',
'roadrunner': 'roadrunner email',
'roadrunner.com': 'roadrunner email',
'roblox sign up': 'roblox game',
'roblox studio': 'roblox game',
'roblox.comtoys': 'roblox game',
'www.roblox.com': 'roblox game',
'roblox promo codes': 'roblox game',
'roblox': 'roblox game',
'roblox gift card': 'roblox game',
'roblox download': 'roblox game',
'roblox games': 'roblox game',
'roblox.com': 'roblox game',
'roblox memes': 'roblox game',
'roblox home': 'roblox game',
'roblox login': 'roblox game',
'roblox music codes': 'roblox game',
'sears outlet': 'sears appliances',
'sears parts direct': 'sears appliances',
'sears': 'sears appliances',
'sears closing': 'sears appliances',
'sears credit card': 'sears appliances',
'social security number': 'social security card',
'social security.gov': 'social security card',
'social security': 'social security card',
'social security calculator': 'social security card',
'social security disability': 'social security card',
'social security office': 'social security card',
'social security administration': 'social security card',
'social security.govmy account': 'social security card',
'social security benefits': 'social security card',
'socialsecurity.gov': 'social security card',
'solitaire game': 'solitaire card games',
'solitaire 247': 'solitaire card games',
'solitaire': 'solitaire card games',
'world of solitaire': 'solitaire card games',
'soundcloud to mp3': 'soundcloud',
'southwest airlines sale': 'southwest airline flights',
'southwest rapid rewards': 'southwest airline flights',
'southwest vacations': 'southwest airline flights',
'southwest airlines flights': 'southwest airline flights',
'www.southwest.com flight': 'southwest airline flights',
'south west airlines': 'southwest airline flights',
'southwest airlines': 'southwest airline flights',
'southwest.com': 'southwest airline flights',
'southwest': 'southwest airline flights',
'southwest check in': 'southwest airline flights',
'southwest airlines check in': 'southwest airline flights',
'www.southwest.com': 'southwest airline flights',
'southwestairlines.com': 'southwest airline flights',
'spectrum internet': 'spectrum mobile',
'spectrum.com': 'spectrum mobile',
'spectrum': 'spectrum mobile',
'spectrum tv': 'spectrum mobile',
'spectrum nyc': 'spectrum mobile',
'spectrum customer service': 'spectrum mobile',
'spectrum speed test': 'spectrum mobile',
'spectrum pay bill': 'spectrum mobile',
'spectrum bill pay': 'spectrum mobile',
'spectrum login': 'spectrum mobile',
'my spectrum': 'spectrum mobile',
'spectrum cable': 'spectrum mobile',
'spectrum.net': 'spectrum mobile',
'spidersolitaire': 'spider solitaire',
'spider solitaire 247': 'spider solitaire',
'spider solitaire two suits': 'spider solitaire',
'spider solitaire 2': 'spider solitaire',
'spirit airline': 'spirit airlines',
'spirit': 'spirit airlines',
'www.spirit.com': 'spirit airlines',
'spotify web player': 'spotify download',
'spotify': 'spotify download',
'spotify student': 'spotify download',
'state tax refund': 'state tax forms 2018',
'state tax forms': 'state tax forms 2018',
'suntrust': 'suntrust online banking',
'suntrust bank': 'suntrust online banking',
'suntrust cash manager': 'suntrust online banking',
'suntrust mortgage': 'suntrust online banking',
'suntrust online': 'suntrust online banking',
'suntrust.com': 'suntrust online banking',
'www.suntrust.com': 'suntrust online banking',
'www.suntrust.com online banking': 'suntrust online banking',
'target baby registry': 'target coupons',
'target': 'target coupons',
'target weekly ad': 'target coupons',
'target red card': 'target coupons',
'target.com': 'target coupons',
'target credit card': 'target coupons',
'target near me': 'target coupons',
'tax': 'tax advisors',
'tax advance': 'tax advisors',
'tax 2018': 'tax advisors',
'tax 1040 form 2018': 'tax advisors',
'tax 2017': 'tax advisors',
'tax amnesty 2018': 'tax advisors',
'tax accountant': 'tax advisors',
'tax 1099 form': 'tax advisors',
'tax brackets 2018': 'tax brackets 2019',
'tax calculator 2018-19': 'tax calculator 2019',
'tax calculator': 'tax calculator 2019',
'tax calculator 2018': 'tax calculator 2019',
'tax filing 2019': 'tax filing',
'tax form 2018': 'tax forms for 2018 filing',
'tax forms helper online 2018': 'tax forms for 2018 filing',
'tax forms': 'tax forms for 2018 filing',
'tax forms 2019': 'tax forms for 2018 filing',
'tax forms 2018': 'tax forms for 2018 filing',
'tax form 1040': 'tax forms for 2018 filing',
'tax forms for 2018': 'tax forms for 2018 filing',
'tax form 1040a': 'tax forms for 2018 filing',
'tax form 1099': 'tax forms for 2018 filing',
'tax forms 1040': 'tax forms for 2018 filing',
'tax form management': 'tax forms for 2018 filing',
'tax preparation': 'tax preparation software',
'tax preparers near me': 'tax preparation software',
'tax preparation services': 'tax preparation software',
'tax preparer near me': 'tax preparation software',
'tax preparation near me': 'tax preparation software',
'tax refund estimator 2019': 'tax refund estimator 2019 irs',
'tax refund calculator': 'tax refund estimator 2019 irs',
'tax refund estimate': 'tax refund estimator 2019 irs',
'tax refund dates 2019': 'tax refund estimator 2019 irs',
'tax refund estimator': 'tax refund estimator 2019 irs',
'tax refund schedule 2019': 'tax refund estimator 2019 irs',
'tax returns online': 'tax returns',
'tax return forms 2018': 'tax returns',
'tax return': 'tax returns',
'tax return estimate ': 'tax returns',
'tax return calculator': 'tax returns',
'tax return estimator': 'tax returns',
'tax return transcript': 'tax returns',
'httpswww.taxslayer.com': 'tax slayer',
'tax software for professionals': 'tax software 2018',
'tax software free': 'tax software 2018',
'tax software': 'tax software 2018',
'tax tables for 2018': 'tax tables for filing 2018 taxes',
'tax tables 2018 irs': 'tax tables for filing 2018 taxes',
'tax tables 2018': 'tax tables for filing 2018 taxes',
'tax tables 2019': 'tax tables for filing 2018 taxes',
'taxable income': 'taxable income 2018',
'taxact 2017 return': 'taxactonline.com',
'taxes online': 'taxactonline.com',
'taxactonline free': 'taxactonline.com',
'taxactonline': 'taxactonline.com',
'taxact': 'taxactonline.com',
'www.taxact.com': 'taxactonline.com',
'taxact or turbotax': 'taxactonline.com',
'taxact commercial': 'taxactonline.com',
'taxact 2018': 'taxactonline.com',
'www.taxact.com 2016': 'taxactonline.com',
'www.taxactonline.com': 'taxactonline.com',
'taxact free': 'taxactonline.com',
'taxact vs turbotax': 'taxactonline.com',
'httpswww.taxact.com': 'taxactonline.com',
'tax act 2019': 'taxactonline.com',
'taxact.com': 'taxactonline.com',
'tax act of 2017': 'taxactonline.com',
'taxact.org': 'taxactonline.com',
'tax act': 'taxactonline.com',
'taxact 2017 login': 'taxactonline.com',
'taxact 2019': 'taxactonline.com',
'tax act of 2018': 'taxactonline.com',
'taxes 2018': 'taxactonline.com',
'taxact login': 'taxactonline.com',
'taxact.com free': 'taxactonline.com',
'taxact.com login': 'taxactonline.com',
'taxact free edition': 'taxactonline.com',
'taxact online': 'taxactonline.com',
'taxact.com reviews': 'taxactonline.com',
'taxact help desk': 'taxactonline.com',
'www.taxact.com sign in': 'taxactonline.com',
'taxact login tax return': 'taxactonline.com',
'www.taxactonline.com sign in': 'taxactonline.com',
'taxact 2017': 'taxactonline.com',
'taxes calculator': 'taxactonline.com',
'taxes 2019': 'taxactonline.com',
'taxact sign in': 'taxactonline.com',
'taxact.com 2016': 'taxactonline.com',
'taxact calculator': 'taxactonline.com',
'taxact professional': 'taxactonline.com',
'taxact promo code': 'taxactonline.com',
'taxact phone number': 'taxactonline.com',
'taxes on a 400k house': 'taxes',
'taxes near me': 'taxes',
'tax free': 'taxes for free',
'tax free usa': 'taxes for free',
'taxhawk 2018': 'taxhawk',
'taxhawk.com': 'taxhawk',
'taxslayer free': 'taxslayer promo code',
'www.taxslayer.com': 'taxslayer promo code',
'taxslayer': 'taxslayer promo code',
'taxslayer.com': 'taxslayer promo code',
'taxslayer login': 'taxslayer promo code',
'taxusa': 'taxusa.com',
'teachers pay teachers': 'teacher pay teachers',
'teacherspayteachers': 'teacher pay teachers',
'teacher pay teacher': 'teacher pay teachers',
'www.teacherspayteachers.com': 'teacher pay teachers',
'www.travelocity.com': 'travelocity flights only',
'travelocity': 'travelocity flights only',
'turbotax.com 2017': 'turbotax.free',
'turbotax 2019': 'turbotax.free',
'turbotax recovery tax return': 'turbotax.free',
'turbotax free': 'turbotax.free',
'turbotax 2017': 'turbotax.free',
'free turbotax': 'turbotax.free',
'turbotax account': 'turbotax.free',
'turbotax for free': 'turbotax.free',
'www.turbotax.com': 'turbotax.free',
'turbotax 2017 online': 'turbotax.free',
'www.turbo tax.com': 'turbotax.free',
'turbotax.com': 'turbotax.free',
'turbotax 2018': 'turbotax.free',
'intuit turbotax': 'turbotax.free',
'turbo tax free': 'turbotax.free',
'tubo tax': 'turbotax.free',
'is turbotax free': 'turbotax.free',
'free turbotax 2019': 'turbotax.free',
'turbotax online 2018': 'turbotax.free',
'turbotax freedom edition 2019': 'turbotax.free',
'httpsmyturbotax.intuit.com': 'turbotax.free',
'turbotax online': 'turbotax.free',
'turbo tax plans': 'turbotax.free',
'turbotax 2018 download': 'turbotax.free',
'turbotax home and business 2018': 'turbotax.free',
'turbotax business 2019': 'turbotax.free',
'turbotax free edition': 'turbotax.free',
'myturbotax': 'turbotax.free',
'www.turbotax.comrenew': 'turbotax.free',
'turbotax 2017 tax return': 'turbotax.free',
'my turbo tax': 'turbotax.free',
'turbotax 2017 login': 'turbotax.free',
'turbotax prepaid card': 'turbotax.free',
'turbotax free edition 2019': 'turbotax.free',
'turbotax intuit': 'turbotax.free',
'turbotax deluxe 2018': 'turbotax.free',
'turbo tax deluxe': 'turbotax.free',
'turbo taxes': 'turbotax.free',
'httpsturbotax.intuit.com': 'turbotax.free',
'turbo tax': 'turbotax.free',
'turbotax sign in': 'turbotax.free',
'turbotax deluxe 2019': 'turbotax.free',
'turbotax.intuit.com': 'turbotax.free',
'turbo tax sign in': 'turbotax.free',
'turbotax': 'turbotax.free',
'turbotax business 2018': 'turbotax.free',
'turbo tax cost': 'turbotax.free',
'turbotax premier 2018': 'turbotax.free',
'turbotax calculator 2018': 'turbotax.free',
'turbotax 2018 premier': 'turbotax.free',
'turbotax estimator': 'turbotax.free',
'intuit turbo tax': 'turbotax.free',
'download turbotax deluxe 2018': 'turbotax.free',
'turbotax calculator': 'turbotax.free',
'www.turbotax.com login': 'turbotax.free',
'turbotax service code': 'turbotax.free',
'turbotax login': 'turbotax.free',
'turbotax customer service': 'turbotax.free',
'twitch prime': 'twitch',
'united airlines reservations': 'united airlines customer service',
'united': 'united airlines customer service',
'united airlines': 'united airlines customer service',
'united airlines flight status': 'united airlines customer service',
'united airlines check in': 'united airlines customer service',
'www.united.com': 'united airlines customer service',
'usaa phone number': 'usaa insurance',
'usaa.com': 'usaa insurance',
'usaa': 'usaa insurance',
'www.usaa.com': 'usaa insurance',
'usaa bank': 'usaa insurance',
'usbank.com': 'usbank online',
'us bank': 'usbank online',
'us bank online': 'usbank online',
'us bank login': 'usbank online',
'us bank online banking': 'usbank online',
'usbank login': 'usbank online',
'usbank': 'usbank online',
'www.usbank.com': 'usbank online',
'httpswww.usps.com': 'usps shipping',
'usps tracking mail': 'usps shipping',
'usps careers': 'usps shipping',
'usps.com': 'usps shipping',
'usps.comcareers': 'usps shipping',
'usps customer service': 'usps shipping',
'usps': 'usps shipping',
'usps tracking': 'usps shipping',
'www.usps.com usps tracking': 'usps shipping',
'www.usps.com': 'usps shipping',
'usps hold mail': 'usps shipping',
'usps mail hold': 'usps shipping',
'usps.comredelivery': 'usps shipping',
'usps zip code search': 'usps shipping',
'usps jobs': 'usps shipping',
'vanguard funds': 'vanguard mutual funds',
'vanguard': 'vanguard mutual funds',
'www.vanguard.com': 'vanguard mutual funds',
'vanguard.com': 'vanguard mutual funds',
'vanguard login': 'vanguard mutual funds',
'vegas world bingo': 'vegas world slots',
'vegasworld': 'vegas world slots',
'vegas world': 'vegas world slots',
'vegasworldfreeslots': 'vegas world slots',
'wayfair promo code': 'wayfair coupon',
'wayfair rugs': 'wayfair coupon',
'wayfair credit card': 'wayfair coupon',
'wayfair': 'wayfair coupon',
'wayfair.com': 'wayfair coupon',
'weather channel forecast': 'weather channel radar',
'weatherchannel.com': 'weather channel radar',
'weather channel': 'weather channel radar',
'weather pittsburgh': 'weather radar',
'weather for tomorrow': 'weather radar',
'weather baltimore': 'weather radar',
'weather underground': 'weather radar',
'weather.com': 'weather radar',
'www.weather.com': 'weather radar',
'weather today': 'weather radar',
'weather st louis': 'weather radar',
'weather forecast': 'weather radar',
'weather nyc': 'weather radar',
'weather nj': 'weather radar',
'weather tomorrow': 'weather radar',
'weather chicago': 'weather radar',
'weather boston': 'weather radar',
'weather washington dc': 'weather radar',
'weather houston': 'weather radar',
'weather': 'weather radar',
'weather los angeles': 'weather radar',
'weather miami': 'weather radar',
'weather atlanta': 'weather radar',
'weather dc': 'weather radar',
'weather in lakewood ca': 'weather radar',
'weather near me': 'weather radar',
'weather cincinnati': 'weather radar',
'weather charlotte hourly': 'weather radar',
'www.wellsfargo.com signon': 'wells fargo bank online',
'wellsfargo': 'wells fargo bank online',
'wells fargo dealer services': 'wells fargo bank online',
'wells fargo login': 'wells fargo bank online',
'wells fargo near me': 'wells fargo bank online',
'wells fargo': 'wells fargo bank online',
'wellsfargo.comcardholders': 'wells fargo bank online',
'wellsfargo.com': 'wells fargo bank online',
'wells fargo login my account': 'wells fargo bank online',
'wells fargo online banking': 'wells fargo bank online',
'www.wellsfargo.com': 'wells fargo bank online',
'wells fargo bank': 'wells fargo bank online',
'wells fargo online': 'wells fargo bank online',
'httpswww.wellsfargo.com': 'wells fargo bank online',
'wells fargo hours': 'wells fargo bank online',
'wells fargo official site': 'wells fargo bank online',
'wells fargo.comsign on': 'wells fargo bank online',
'wellsfargo online': 'wells fargo bank online',
'wellsfargo.commynewcard': 'wells fargo bank online',
'wellsfargoadvisors': 'wells fargo bank online',
'when can you start filing taxes': 'when can i file my 2018 taxes electronically',
'when can i file taxes for 2019': 'when can i file my 2018 taxes electronically',
'when to file tax return 2019': 'when can i file my 2018 taxes electronically',
'which is the tax free investments': 'where can i file my taxes for free',
'where to file taxes': 'where can i file my taxes for free',
'where can i get tax forms for 2018': 'where can i file my taxes for free',
'where can senior get their taxes filed free in kingwood wva contacts': 'where can i file my taxes for free',
'where is my tax refund': 'where can i file my taxes for free',
'white pages': 'whitepages reverse lookup',
'whitepages': 'whitepages reverse lookup',
'abcya 1000': 'www.abcya.com',
'abcya 4th grade': 'www.abcya.com',
'abcya': 'www.abcya.com',
'abc ya': 'www.abcya.com',
'abcya 2': 'www.abcya.com',
'abcya grade 3': 'www.abcya.com',
'abcya3': 'www.abcya.com',
'abcya duck life 5': 'www.abcya.com',
'abcya math': 'www.abcya.com',
'abcya 4': 'www.abcya.com',
'abcya.com': 'www.abcya.com',
'abcya grade 1': 'www.abcya.com',
'abcya games': 'www.abcya.com',
'abcya duck life': 'www.abcya.com',
'abcya 1': 'www.abcya.com',
'american airlines': 'www.americanairlines.com',
'american airlines reservations': 'www.americanairlines.com',
'american airlines flight status': 'www.americanairlines.com',
'american airlines flights': 'www.americanairlines.com',
'american airlines check in': 'www.americanairlines.com',
'ancestrydna.comactivate': 'www.ancestry.com',
'ancestry': 'www.ancestry.com',
'ancestry dna': 'www.ancestry.com',
'ancestry.com sign in': 'www.ancestry.com',
'ancestry login': 'www.ancestry.com',
'ancestry.com free': 'www.ancestry.com',
'cool math games snake': 'www.coolmath.com',
'cool math games run 3': 'www.coolmath.com',
'cool math run 3': 'www.coolmath.com',
'coolmath4kids': 'www.coolmath.com',
'cool math games': 'www.coolmath.com',
'cool wallpapers': 'www.coolmath.com',
'cool math': 'www.coolmath.com',
'coolmath': 'www.coolmath.com',
'costco': 'www.costco.com',
'costco car rental': 'www.costco.com',
'costco near me': 'www.costco.com',
'costco.com': 'www.costco.com',
'costco membership': 'www.costco.com',
'costco online': 'www.costco.com',
'costco hours': 'www.costco.com',
'craigslist fargo': 'www.craigslist.org',
'craigslist utah': 'www.craigslist.org',
'craigslist bay area': 'www.craigslist.org',
'craigslist san jose': 'www.craigslist.org',
'craigslist harrisburg': 'www.craigslist.org',
'craigslist sc': 'www.craigslist.org',
'craigslist li': 'www.craigslist.org',
'craigslist grand rapids': 'www.craigslist.org',
'craigslist houston': 'www.craigslist.org',
'craigslist south florida': 'www.craigslist.org',
'craigslist binghamton': 'www.craigslist.org',
'craigslist charleston': 'www.craigslist.org',
'craigslist nc': 'www.craigslist.org',
'www.craigslist.com': 'www.craigslist.org',
'craigslist.com': 'www.craigslist.org',
'craigslist duluth': 'www.craigslist.org',
'craigslist albany ny': 'www.craigslist.org',
'craigslist.org': 'www.craigslist.org',
'craigslist richmond': 'www.craigslist.org',
'craigslist dayton': 'www.craigslist.org',
'craigslist eugene': 'www.craigslist.org',
'craigslist boston': 'www.craigslist.org',
'craigslist baltimore': 'www.craigslist.org',
'craigslist spokane': 'www.craigslist.org',
'craigslist jackson ms': 'www.craigslist.org',
'craigslist sd': 'www.craigslist.org',
'craigslist ny': 'www.craigslist.org',
'craigslist amarillo': 'www.craigslist.org',
'craigslist orange county': 'www.craigslist.org',
'craigslist mobile': 'www.craigslist.org',
'craigslist austin': 'www.craigslist.org',
'craigslist phoenix': 'www.craigslist.org',
'craigslist monterey': 'www.craigslist.org',
'craigslist charlotte': 'www.craigslist.org',
'craigslist greenville': 'www.craigslist.org',
'craigslist des moines': 'www.craigslist.org',
'craigslist modesto': 'www.craigslist.org',
'craigslist stockton': 'www.craigslist.org',
'craigslist lexington': 'www.craigslist.org',
'craigslist mcallen': 'www.craigslist.org',
'craigslist cape cod': 'www.craigslist.org',
'craigslist kc': 'www.craigslist.org',
'craigslist': 'www.craigslist.org',
'craigslist green bay': 'www.craigslist.org',
'craigslist columbus': 'www.craigslist.org',
'craigslist oc': 'www.craigslist.org',
'craigslist indianapolis': 'www.craigslist.org',
'craigslist va': 'www.craigslist.org',
'craigslist cincinnati': 'www.craigslist.org',
'craigslist california': 'www.craigslist.org',
'craigslist dc': 'www.craigslist.org',
'craigslist nj': 'www.craigslist.org',
'craigslist ie': 'www.craigslist.org',
'craigslist san diego': 'www.craigslist.org',
'craigslist dfw': 'www.craigslist.org',
'craigslist vt': 'www.craigslist.org',
'craiglist': 'www.craigslist.org',
'craigslist wisconsin': 'www.craigslist.org',
'craigslist oahu': 'www.craigslist.org',
'craigslist ri': 'www.craigslist.org',
'craigslist medford': 'www.craigslist.org',
'craigslist miami': 'www.craigslist.org',
'craigslist md': 'www.craigslist.org',
'craigslist pa': 'www.craigslist.org',
'craigslist kpr': 'www.craigslist.org',
'craigslist nashville': 'www.craigslist.org',
'craigslist stl': 'www.craigslist.org',
'craigslist seattle': 'www.craigslist.org',
'craigslist arkansas': 'www.craigslist.org',
'craigslist dallas': 'www.craigslist.org',
'craigslist hawaii': 'www.craigslist.org',
'craigslist texas': 'www.craigslist.org',
'craigslist ct': 'www.craigslist.org',
'craigslist new orleans': 'www.craigslist.org',
'craigslist tampa': 'www.craigslist.org',
'craigslist rochester ny': 'www.craigslist.org',
'craigslist kentucky': 'www.craigslist.org',
'craigslist south jersey': 'www.craigslist.org',
'craigslist ventura': 'www.craigslist.org',
'craigslist colorado springs': 'www.craigslist.org',
'craigslist utica': 'www.craigslist.org',
'craigslist pittsburgh': 'www.craigslist.org',
'craigslist columbia mo': 'www.craigslist.org',
'craigslist maine': 'www.craigslist.org',
'craigslist roanoke': 'www.craigslist.org',
'craigslist chicago': 'www.craigslist.org',
'craigslist las vegas': 'www.craigslist.org',
'craigslist philadelphia': 'www.craigslist.org',
'craigslist denver': 'www.craigslist.org',
'craigslist akron': 'www.craigslist.org',
'craigslist macon': 'www.craigslist.org',
'craigslist tulsa': 'www.craigslist.org',
'craigslist western mass': 'www.craigslist.org',
'craigslist atlanta': 'www.craigslist.org',
'craigslist sacramento': 'www.craigslist.org',
'craigslist knoxville': 'www.craigslist.org',
'craigslist new haven': 'www.craigslist.org',
'craigslist albany': 'www.craigslist.org',
'craigslist madison': 'www.craigslist.org',
'craigslist nh': 'www.craigslist.org',
'craigslist orlando': 'www.craigslist.org',
'craigslist detroit': 'www.craigslist.org',
'craigslist la': 'www.craigslist.org',
'craigslist raleigh': 'www.craigslist.org',
'craigslist slo': 'www.craigslist.org',
'craigs list': 'www.craigslist.org',
'craigslist michigan': 'www.craigslist.org',
'craigslist florida': 'www.craigslist.org',
'craigslist portland': 'www.craigslist.org',
'craigslist jacksonville': 'www.craigslist.org',
'craigslist san antonio': 'www.craigslist.org',
'craigslist minneapolis': 'www.craigslist.org',
'craigslist mn': 'www.craigslist.org',
'craigslist boise': 'www.craigslist.org',
'craigslist sf': 'www.craigslist.org',
'craigslist altoona': 'www.craigslist.org',
'craigslist asheville': 'www.craigslist.org',
'craigslist az': 'www.craigslist.org',
'craigslist bakersfield': 'www.craigslist.org',
'craigslist bellingham': 'www.craigslist.org',
'craigslist bend': 'www.craigslist.org',
'craigslist big island': 'www.craigslist.org',
'craigslist bloomington': 'www.craigslist.org',
'craigslist boulder': 'www.craigslist.org',
'craigslist buffalo': 'www.craigslist.org',
'craigslist cleveland': 'www.craigslist.org',
'craigslist eastern ct': 'www.craigslist.org',
'craigslist eau claire': 'www.craigslist.org',
'craigslist el paso': 'www.craigslist.org',
'craigslist flint': 'www.craigslist.org',
'craigslist fort wayne': 'www.craigslist.org',
'craigslist fresno': 'www.craigslist.org',
'craigslist inland empire': 'www.craigslist.org',
'craigslist kalispell': 'www.craigslist.org',
'craigslist ky': 'www.craigslist.org',
'craigslist lansing': 'www.craigslist.org',
'craigslist los angeles': 'www.craigslist.org',
'craigslist memphis': 'www.craigslist.org',
'craigslist milwaukee': 'www.craigslist.org',
'craigslist norfolk': 'www.craigslist.org',
'craigslist north bay': 'www.craigslist.org',
'craigslist ohio': 'www.craigslist.org',
'craigslist okc': 'www.craigslist.org',
'craigslist omaha': 'www.craigslist.org',
'craigslist pensacola': 'www.craigslist.org',
'craigslist redding': 'www.craigslist.org',
'craigslist reno': 'www.craigslist.org',
'craigslist salem oregon': 'www.craigslist.org',
'craigslist san francisco': 'www.craigslist.org',
'craigslist sarasota': 'www.craigslist.org',
'craigslist south bend': 'www.craigslist.org',
'craigslist tucson': 'www.craigslist.org',
'craigslist wausau': 'www.craigslist.org',
'craigslist western slope': 'www.craigslist.org',
'craigslist wichita': 'www.craigslist.org',
'craigslist yakima': 'www.craigslist.org',
'huntington online bank': 'www.huntingtonbank.com',
'huntington bank': 'www.huntingtonbank.com',
'huntington.com': 'www.huntingtonbank.com',
'huntington national bank': 'www.huntingtonbank.com',
'huntington online banking': 'www.huntingtonbank.com',
'ixl.com': 'www.ixl.com',
'ixl': 'www.ixl.com',
'ixl math': 'www.ixl.com',
'msn.com': 'www.msn.com',
'msn': 'www.msn.com',
'msn news': 'www.msn.com',
'msn games': 'www.msn.com',
'msn home page': 'www.msn.com',
'msn hotmail sign in': 'www.msn.com',
'msn latino': 'www.msn.com',
'msn money': 'www.msn.com',
'msn sports': 'www.msn.com',
'msn.com news': 'www.msn.com',
'quizlet.live': 'www.quizlet.com',
'quizlet live': 'www.quizlet.com',
'quizlet': 'www.quizlet.com',
'quizlet.com': 'www.quizlet.com',
'realtor': 'www.realtor.com',
'realtor.com fl': 'www.realtor.com',
'realtor.com': 'www.realtor.com',
'tdameritrade.com': 'www.tdameritrade.com',
'td ameritrade': 'www.tdameritrade.com',
'td banking online': 'www.tdameritrade.com',
'td bank login': 'www.tdameritrade.com',
'tdameritrade login': 'www.tdameritrade.com',
'td ameritrade login': 'www.tdameritrade.com',
'td auto finance': 'www.tdameritrade.com',
'td bank': 'www.tdameritrade.com',
'tdameritrade': 'www.tdameritrade.com',
'tdbank login': 'www.tdameritrade.com',
't d ameritrade': 'www.tdameritrade.com',
'td bank online': 'www.tdameritrade.com',
'td online banking': 'www.tdameritrade.com',
'ups customer service': 'www.ups.com',
'ups careers': 'www.ups.com',
'ups tracking number': 'www.ups.com',
'ups tracking order': 'www.ups.com',
'ups tracking': 'www.ups.com',
'ups pickup': 'www.ups.com',
'ups': 'www.ups.com',
'ups.com': 'www.ups.com',
'ups freight tracking': 'www.ups.com',
'ups near me': 'www.ups.com',
'ups store': 'www.ups.com',
'ups store near me': 'www.ups.com',
'verizon fios': 'www.verizon.com',
'verizon plans': 'www.verizon.com',
'verizon near me': 'www.verizon.com',
'verizon prepaid': 'www.verizon.com',
'verizon.com': 'www.verizon.com',
'verizon customer service': 'www.verizon.com',
'verizon': 'www.verizon.com',
'my verizon': 'www.verizon.com',
'verizon store': 'www.verizon.com',
'verizon wireless login': 'www.verizon.com',
'verizonwireless.com': 'www.verizon.com',
'my verizon bill': 'www.verizon.com',
'verizon wireless': 'www.verizon.com',
'www.verizonwireless.com': 'www.verizon.com',
'verizon.compayonline': 'www.verizon.com',
'verizon email': 'www.verizon.com',
'www.verizonwireless.com myverizon': 'www.verizon.com',
'verizon bill pay': 'www.verizon.com',
'verizon internet': 'www.verizon.com',
'verizon store near me': 'www.verizon.com',
'verizon yahoo': 'www.verizon.com',
'walmart tires': 'www.walmart.com',
'walmart photo': 'www.walmart.com',
'walmart': 'www.walmart.com',
'walmart application': 'www.walmart.com',
'walmart pharmacy': 'www.walmart.com',
'walmart near me': 'www.walmart.com',
'walmart grocery pickup': 'www.walmart.com',
'walmart money card': 'www.walmart.com',
'walmart auto center': 'www.walmart.com',
'walmart.com': 'www.walmart.com',
'walmart credit card': 'www.walmart.com',
'www.walmartone.com': 'www.walmart.com',
'walmartone': 'www.walmart.com',
'wish': 'www.wish.com',
'xfinity internet': 'xfinity official site',
'xfinity speed test': 'xfinity official site',
'www.xfinity.com': 'xfinity official site',
'xfinity stream': 'xfinity official site',
'xfinity.commyaccount': 'xfinity official site',
'xfinity email': 'xfinity official site',
'xfinity login': 'xfinity official site',
'xfinity': 'xfinity official site',
'xfinity mobile': 'xfinity official site',
'www.xfinity.commyaccount': 'xfinity official site',
'xfinity.com': 'xfinity official site',
'my xfinity': 'xfinity official site',
'www.xnxx.com': 'xnxx.com',
'yahoo.com': 'yahoo',
'yahoo finance quotes': 'yahoo',
'yahoo sports': 'yahoo',
'yahoo news': 'yahoo',
'yahoo search': 'yahoo',
'yah': 'yahoo',
'yahoonews.com': 'yahoo',
'yahoo finance': 'yahoo',
'www.yahoo.com': 'yahoo',
'my yahoo': 'yahoo',
'yahoo espanol': 'yahoo',
'yahoo india': 'yahoo',
'yahoo japan': 'yahoo',
'yahoo news and headlines': 'yahoo',
'yahoo news today': 'yahoo',
'yahoo mail': 'yahoo.com mail',
'yahoo.com email': 'yahoo.com mail',
'yahoo mail login': 'yahoo.com mail',
'yahoo.com mail signin': 'yahoo.com mail',
'yahoo mail app': 'yahoo.com mail',
'yahoomail': 'yahoo.com mail',
'youtube downloader': 'youtube free movies',
'youtube movies free': 'youtube free movies',
'you tube movies': 'youtube free movies',
'youtube tv': 'youtube free movies',
'youtube.comactivate': 'youtube free movies',
'you tube kids': 'youtube free movies',
'youtube music': 'youtube free movies',
'youtube app': 'youtube free movies',
'youtube videos': 'youtube free movies',
'www.youtube.com': 'youtube free movies',
'youtube kids': 'youtube free movies',
'you tube videos': 'youtube free movies',
'you tube': 'youtube free movies',
'youtube movies': 'youtube free movies',
'youtube video downloader': 'youtube free movies',
'youtube': 'youtube free movies',
'utube': 'youtube free movies',
'yotube': 'youtube free movies',
'you tube.comactivate': 'youtube free movies',
'yout': 'youtube free movies',
'youtbe': 'youtube free movies',
'youtobe': 'youtube free movies',
'youtub': 'youtube free movies',
'youtube broadcast yourself': 'youtube free movies',
'youtube christian music': 'youtube free movies',
'youtube kids videos': 'youtube free movies',
'youtube movies full': 'youtube free movies',
'youtube music videos': 'youtube free movies',
'youtube.com': 'youtube free movies',
'youtube.com music': 'youtube free movies',
'youtube': 'youtube free movies',
'youtubr': 'youtube free movies',
'youtube mp3 converter': 'youtube mp3',
'youtube to mp3': 'youtube mp3',
'youtube converter': 'youtube mp3',
'youtube to mp3 converter': 'youtube mp3',
'youtube to mp4': 'youtube mp3',
'zillow.com': 'zillow home values',
'zillow for sale': 'zillow home values',
'zillow rentals': 'zillow home values',
'zillows': 'zillow home values',
'zillow homes for sale': 'zillow home values',
'zillow rental': 'zillow home values',
'zillow': 'zillow home values',
'www.zillow.com': 'zillow home values',
'zillow nj': 'zillow home values',
'zillow ma': 'zillow home values'
};