export default class AdBistro {

    constructor(config) {
        this.active = config.adProvider.includes("direct");
    }
    getAd(term) {
        return Promise.resolve([]);
        let qsArgsAdBistro = "fmt=json&aff_id=80268&at=BN7JP9L9Q&dc=2&cs=1&mt=" + encodeURIComponent(term);
        var adBistroResults = fetch('//api.absearchapi.com/search?' + qsArgsAdBistro,{
            method: 'GET'
        }).then(response => response.json())
        .then(json => json.map(item => { return { source: 'adbistro', title: item.title, click_url: item.click_url, image_url: item.image_url }; }))
        .catch(error => []);

        return adBistroResults;
    }
}