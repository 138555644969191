import { suggestionsFuzzy } from '../functions/suggestions_fuzzy';
import { suggestionsBlackFriday } from '../functions/suggestions_black_friday';

export default class HomeDepotAd
{
    static adToUse(suggestions) {
        let found = suggestionsFuzzy(suggestions,['home depot', 'homedepot']) || suggestionsBlackFriday(suggestions,['home depot','homedepot']);
        if (found) {
            return {
                source: "adbistro",
                provider: 'homedepot_impact',
                title: "The Home Depot",
                click_url: "https://homedepot.sjv.io/c/1257166/456723/8154?subId1=Search_ext&u=https%3A%2F%2Fwww.homedepot.com%2F",
                image_url: "https://fsquizmast.blob.core.windows.net/images/1_adbistro/20181030/home_depot-fzTSuXHUAYA.png"
            };
        } else {
            return null;
        }
    }
}
