import { suggestionsFuzzy } from '../functions/suggestions_fuzzy';
export default class OurAds
{
    static adToUse(suggestions) {
        let found = suggestionsFuzzy(suggestions, ['adam and eve','adamandeve']);
        if (found) {
            return {
                source: "adbistro",
                provider: "cj",
                title: "Adam and Eve",
                click_url: "http://www.jdoqocy.com/click-8827574-13019640",
                image_url: "https://fsquizmast.blob.core.windows.net/images/1_adbistro/20181005/adam_and_eve-uPdNUJZJAYA.png"
            };
        }

        found = suggestionsFuzzy(suggestions, ['world market','worldmarket']);
        if (found) {
            return {
                source: "adbistro",
                provider: "cj",
                title: "World Market",
                click_url: "http://www.tkqlhce.com/click-8827574-11413781",
                image_url: "https://fsquizmast.blob.core.windows.net/images/1_adbistro/20181005/costplus-61rW0B2JAYA.png"
            };
        }

        found = suggestionsFuzzy(suggestions, ['lifelock','life lock']);
        if (found) {
            return {
                source: "adbistro",
                provider: "cj",
                title: "LifeLock",
                click_url: "http://www.tkqlhce.com/click-8827574-13070580",
                image_url: "https://fsquizmast.blob.core.windows.net/images/1_adbistro/20181005/lifelock-UQcA5B2JAYA.png"
            };
        }

        // found = suggestionsFuzzy(suggestions, ['lens']);
        // if (found) {
        //     return {
        //         source: "adbistro",
        //         provider: "cj",
        //         title: "Contact Lenses at Lens.com",
        //         click_url: "http://www.dpbolvw.net/click-8827574-10371955",
        //         image_url: "https://fsquizmast.blob.core.windows.net/images/1_adbistro/20181005/lenscom-rmMtXR2JAYA.png"
        //     };
        // }

        found = suggestionsFuzzy(suggestions, ['cafepress','cafe press']);
        if (found) {
            return {
                source: "adbistro",
                provider: "cj",
                title: "Cafepress",
                click_url: "http://www.anrdoezrs.net/click-8827574-10887958",
                image_url: "https://fsquizmast.blob.core.windows.net/images/1_adbistro/20181005/cafepress-QhQZYBZJAYA.png"
            };
        }

        found = suggestionsFuzzy(suggestions, ['airefarewatchdog','airefare watchdog','airfarewatchdog','airfare watchdog']);
        if (found) {
            return {
                source: "adbistro",
                provider: "cj",
                title: "Airefarewatchdog",
                click_url: "http://www.anrdoezrs.net/click-8827574-12873251",
                image_url: "https://fsquizmast.blob.core.windows.net/images/1_adbistro/20181005/awd_logo-srFn4R2JAYA.png"
            };
        }

        found = suggestionsFuzzy(suggestions, ['autobarn','auto barn']);
        if (found) {
            return {
                source: "adbistro",
                provider: "cj",
                title: "Auto Barn",
                click_url: "http://www.kqzyfj.com/click-8827574-5656364",
                image_url: "https://fsquizmast.blob.core.windows.net/images/1_adbistro/20181107/autobarn-uqWitpfXAYA.png"
            };
        }

        found = suggestionsFuzzy(suggestions, ['avianca']);
        if (found) {
            return {
                source: "adbistro",
                provider: "cj",
                title: "Avianca",
                click_url: "http://www.anrdoezrs.net/click-8827574-13494292",
                image_url: "https://fsquizmast.blob.core.windows.net/images/1_adbistro/20181107/avianca-f8mc0ls3AMA.png"
            };
        }

        // found = suggestionsFuzzy(suggestions, ['calendar']);
        // if (found) {
        //     return {
        //         source: "adbistro",
        //         provider: "cj",
        //         title: "Calendars.com",
        //         click_url: "http://www.anrdoezrs.net/click-8827574-10481146",
        //         image_url: "https://fsquizmast.blob.core.windows.net/images/1_adbistro/20181005/calendarscom-exlMyZZJAYA.png"
        //     };
        // }

        found = suggestionsFuzzy(suggestions, ['cheapair','cheap air']);
        if (found) {
            return {
                source: "adbistro",
                provider: "cj",
                title: "Cheap Air",
                click_url: "http://www.kqzyfj.com/click-8827574-11635001",
                image_url: "https://fsquizmast.blob.core.windows.net/images/1_adbistro/20181005/cheapair-FC8s7yneAMA.png"
            };
        }

        found = suggestionsFuzzy(suggestions, ['expedia']);
        if (found) {
            return {
                source: "adbistro",
                provider: "cj",
                title: "Expedia",
                click_url: "http://www.kqzyfj.com/click-8827574-12639039",
                image_url: "https://fsquizmast.blob.core.windows.net/images/1_adbistro/20181025/expedia-qeGqycBSAYA.png"
            };
        }

        found = suggestionsFuzzy(suggestions, ['fye']);
        if (found) {
            return {
                source: "adbistro",
                provider: "cj",
                title: "FYE",
                click_url: "http://www.tkqlhce.com/click-8827574-12999073",
                image_url: "https://fsquizmast.blob.core.windows.net/images/1_adbistro/20181107/fye-GFlCsxfXAYA.png"
            };
        }

        found = suggestionsFuzzy(suggestions, ['livingsocial','living social']);
        if (found) {
            return {
                source: "adbistro",
                provider: "cj",
                title: "LivingSocial",
                click_url: "http://www.kqzyfj.com/click-8827574-11486842",
                image_url: "https://fsquizmast.blob.core.windows.net/images/1_adbistro/20181005/livingsocial-sEPqTZZJAYA.png"
            };
        }

        found = suggestionsFuzzy(suggestions, ['patpat','pat pat']);
        if (found) {
            return {
                source: "adbistro",
                provider: "cj",
                title: "PatPat",
                click_url: "http://www.anrdoezrs.net/click-8827574-12591177",
                image_url: "https://fsquizmast.blob.core.windows.net/images/1_adbistro/20181022/patpat-tBw8TkfIQMA.png"
            };
        }

        found = suggestionsFuzzy(suggestions, ['pureflix','pure flix']);
        if (found) {
            return {
                source: "adbistro",
                provider: "cj",
                title: "Pureflix",
                click_url: "http://www.tkqlhce.com/click-8827574-12619443",
                image_url: "https://fsquizmast.blob.core.windows.net/images/1_adbistro/20181026/pureflix-PDUJaSLsAYA.png"
            };
        }

        found = suggestionsFuzzy(suggestions, ['showtickets','show tickets']);
        if (found) {
            return {
                source: "adbistro",
                provider: "cj",
                title: "ShowTickets.com",
                click_url: "http://www.jdoqocy.com/click-8827574-13378450",
                image_url: "https://fsquizmast.blob.core.windows.net/images/1_adbistro/20181030/showtickets-bCbg9X9VAGA.png"
            };
        }

        found = suggestionsFuzzy(suggestions, ['spafinder','spa finder']);
        if (found) {
            return {
                source: "adbistro",
                provider: "cj",
                title: "Spafinder",
                click_url: "http://www.jdoqocy.com/click-8827574-13451801",
                image_url: "https://fsquizmast.blob.core.windows.net/images/1_adbistro/20181005/spafinder-pyqcYB2JAYA.png"
            };
        }

        found = suggestionsFuzzy(suggestions, ['tigerdirect','tiger direct']);
        if (found) {
            return {
                source: "adbistro",
                provider: "cj",
                title: "Tiger Direct",
                click_url: "http://www.kqzyfj.com/click-8827574-12766037",
                image_url: "https://fsquizmast.blob.core.windows.net/images/1_adbistro/20181005/tigerdirect-wm1S7R2JAYA.png"
            };
        }

        // found = suggestionsFuzzy(suggestions, ['bookingbuddy','booking buddy']);
        // if (found) {
        //     return {
        //         source: "adbistro",
        //         provider: "bookingbuddy",
        //         title: "Booking Buddy",
        //         click_url: "https://prf.hn/click/camref:1101l4nMX/destination:http:/www.bookingbuddy.com/en-US/hero/?mode=air&currency=USD&tab_limit=7",
        //         image_url: "https://fsquizmast.blob.core.windows.net/images/1_adbistro/20181019/bookingbuddy-W5uH1XLPAYA.png"
        //     };
        // }

        found = suggestionsFuzzy(suggestions, ['fiverr']);
        if (found) {
            return {
                source: "adbistro",
                provider: "awin",
                title: "Fiverr",
                click_url: "http://www.awin1.com/cread.php?awinmid=6288&awinaffid=533367&clickref=&p=https%3A%2F%2Fwww.fiverr.com%2F%3Fshow_join%3Dtrue",
                image_url: "https://fsquizmast.blob.core.windows.net/images/1_adbistro/20181030/fiverr-20fEwPDUAYA.png"
            };
        }

        found = suggestionsFuzzy(suggestions, ['axa travel','axa flight','axatravel']);
        if (found) {
            return {
                source: "adbistro",
                provider: "awin",
                title: "AXA Travel Insurance",
                click_url: "http://www.awin1.com/awclick.php?mid=7970&id=533367",
                image_url: "//s3.amazonaws.com/autosuggest-files/logos/axa.png"
            };
        }

        found = suggestionsFuzzy(suggestions, ['winebasket','wine basket']);
        if (found) {
            return {
                source: "adbistro",
                provider: "cj",
                title: "winebasket.com",
                click_url: "http://www.tkqlhce.com/click-8913767-10751904",
                image_url: "//s3.amazonaws.com/autosuggest-files/logos/winebasket.png"
            };
        }

        found = suggestionsFuzzy(suggestions, ['sonos']);
        if (found) {
            return {
                source: "adbistro",
                provider: "cj",
                title: "Sonos",
                click_url: "http://www.kqzyfj.com/click-8827574-10780087",
                image_url: "//s3.amazonaws.com/autosuggest-files/logos/sonos.png"
            };
        }

        found = suggestionsFuzzy(suggestions, ['booking.com']);
        if (found) {
            return {
                source: "adbistro",
                provider: "booking.com",
                title: "Booking.com",
                click_url: "https://www.booking.com/index.html?aid=1653767",
                image_url: "//s3.amazonaws.com/autosuggest-files/logos/bookingcom.png"
            };
        }

        found = suggestionsFuzzy(suggestions, ['giftcard','gift card']);
        if (found) {
            return {
                source: "adbistro",
                provider: "cj",
                title: "GiftCards.com",
                click_url: "http://www.jdoqocy.com/click-8827574-13369220",
                image_url: "//s3.amazonaws.com/autosuggest-files/logos/giftcardscom.png"
            };
        }

        found = suggestionsFuzzy(suggestions, ['huk']);
        if (found) {
            return {
                source: "adbistro",
                provider: "cj",
                title: "Huk Performance Fishing",
                click_url: "http://www.tkqlhce.com/click-8827574-13586149",
                image_url: "//s3.amazonaws.com/autosuggest-files/logos/hukgear.png"
            };
        }

        found = suggestionsFuzzy(suggestions, ['overstock']);
        if (found) {
            return {
                source: "adbistro",
                provider: "viglink",
                title: "Overstock.com",
                click_url: "http://redirect.viglink.com?key=8899039f90b318987523f0cdea660b71&u=http%3A%2F%2Foverstock.com",
                image_url: "//s3.amazonaws.com/autosuggest-files/logos/overstock.png"
            };
        }

        found = suggestionsFuzzy(suggestions, ['sandals.com']);
        if (found) {
            return {
                source: "adbistro",
                provider: "namespace",
                title: "Sandals",
                click_url: "http://navigation.nsgnav.com/query.php?p=SSS&a=nav&qid=13313326",
                image_url: "//s3.amazonaws.com/autosuggest-files/logos/sandals.png"
            };
        }

        found = suggestionsFuzzy(suggestions, ['1 ink','1ink']);
        if (found) {
            return {
                source: "adbistro",
                provider: "viglink",
                title: "1ink",
                click_url: "http://redirect.viglink.com?key=8899039f90b318987523f0cdea660b71&u=http%3A%2F%2F1ink.com",
                image_url: "//s3.amazonaws.com/autosuggest-files/logos/1inkcom.png"
            };
        }

        found = suggestionsFuzzy(suggestions, ['advance auto parts','advanceautoparts']);
        if (found) {
            return {
                source: "adbistro",
                provider: "viglink",
                title: "Advance Auto Parts",
                click_url: "http://redirect.viglink.com?key=8899039f90b318987523f0cdea660b71&u=http%3A%2F%2Fadvanceautoparts.com",
                image_url: "//s3.amazonaws.com/autosuggest-files/logos/advanceautoparts.png"
            };
        }

        found = suggestionsFuzzy(suggestions, ['bissell']);
        if (found) {
            return {
                source: "adbistro",
                provider: "viglink",
                title: "Bissell",
                click_url: "http://redirect.viglink.com?key=8899039f90b318987523f0cdea660b71&u=http%3A%2F%2Fbissell.com",
                image_url: "//s3.amazonaws.com/autosuggest-files/logos/bissell.png"
            };
        }

        found = suggestionsFuzzy(suggestions, ['dkny']);
        if (found) {
            return {
                source: "adbistro",
                provider: "viglink",
                title: "DKNY",
                click_url: "http://redirect.viglink.com?key=8899039f90b318987523f0cdea660b71&u=http%3A%2F%2Fdkny.com",
                image_url: "//s3.amazonaws.com/autosuggest-files/logos/dkny.png"
            };
        }

        found = suggestionsFuzzy(suggestions, ['godiva']);
        if (found) {
            return {
                source: "adbistro",
                provider: "viglink",
                title: "GODIVA Chocolates",
                click_url: "http://redirect.viglink.com?key=8899039f90b318987523f0cdea660b71&u=http%3A%2F%2Fgodiva.com",
                image_url: "//s3.amazonaws.com/autosuggest-files/logos/godiva.png"
            };
        }

        found = suggestionsFuzzy(suggestions, ['1800contact','1800 contact','1 800 contact','1-800 contact','800contact','800 contact','1-800-contact']);
        if (found) {
            return {
                source: "adbistro",
                provider: "viglink",
                title: "1-800 Contacts",
                click_url: "http://redirect.viglink.com?key=8899039f90b318987523f0cdea660b71&u=http%3A%2F%2F1800contacts.com",
                image_url: "//s3.amazonaws.com/autosuggest-files/logos/1800contacts.png"
            };
        }

        found = suggestionsFuzzy(suggestions, ['zulily']);
        if (found) {
            return {
                source: "adbistro",
                provider: "viglink",
                title: "Zulily",
                click_url: "http://redirect.viglink.com?key=8899039f90b318987523f0cdea660b71&u=http%3A%2F%2Fzulily.com",
                image_url: "//s3.amazonaws.com/autosuggest-files/logos/zulily.png"
            };
        }

        found = suggestionsFuzzy(suggestions, ['coldwatercreek','coldwater creek']);
        if (found) {
            return {
                source: "adbistro",
                provider: "viglink",
                title: "Coldwater Creek",
                click_url: "http://redirect.viglink.com?key=8899039f90b318987523f0cdea660b71&u=http%3A%2F%2Fcoldwatercreek.com",
                image_url: "//s3.amazonaws.com/autosuggest-files/logos/coldwatercreek.png"
            };
        }

        // found = suggestionsFuzzy(suggestions, ['nautica']);
        // if (found) {
        //     return {
        //         source: "adbistro",
        //         provider: "viglink",
        //         title: "Nautica",
        //         click_url: "http://redirect.viglink.com?key=8899039f90b318987523f0cdea660b71&u=http%3A%2F%2Fnautica.com",
        //         image_url: "//s3.amazonaws.com/autosuggest-files/logos/nautica.png"
        //     };
        // }

        found = suggestionsFuzzy(suggestions, ['shutterfly']);
        if (found) {
            return {
                source: "adbistro",
                provider: "viglink",
                title: "Shutterfly",
                click_url: "http://redirect.viglink.com?key=8899039f90b318987523f0cdea660b71&u=http%3A%2F%2Fshutterfly.com",
                image_url: "//s3.amazonaws.com/autosuggest-files/logos/shutterfly.png"
            };
        }

        found = suggestionsFuzzy(suggestions, ['victoria secret','victoriasecret','victorias secret','victorias secret','victoria\'s secret']);
        if (found) {
            return {
                source: "adbistro",
                provider: "viglink",
                title: "Victoria's Secret",
                click_url: "http://redirect.viglink.com?key=8899039f90b318987523f0cdea660b71&u=http%3A%2F%2Fvictoriassecret.com",
                image_url: "//s3.amazonaws.com/autosuggest-files/logos/victoriasecret.png"
            };
        }

        found = suggestionsFuzzy(suggestions, ['snapfish','snap fish']);
        if (found) {
            return {
                source: "adbistro",
                provider: "snapfish",
                title: "Snapfish",
                click_url: "https://snapfish.jedg.net/PaR26",
                image_url: "//s3.amazonaws.com/autosuggest-files/logos/snapfish.png"
            };
        }

        found = suggestionsFuzzy(suggestions, ['shein']);
        if (found) {
            return {
                source: "adbistro",
                provider: "cj",
                title: "SheIn.com",
                click_url: "http://www.dpbolvw.net/click-8827574-13436888",
                image_url: "//s3.amazonaws.com/autosuggest-files/logos/shein.png"
            };
        }

        found = suggestionsFuzzy(suggestions, ['countryinn','country inn']);
        if (found) {
            return {
                source: "adbistro",
                provider: "cj",
                title: "Country Inn & Suites by Radisson",
                click_url: "http://www.jdoqocy.com/click-8827574-13398600",
                image_url: "//s3.amazonaws.com/autosuggest-files/logos/countryinn.png"
            };
        }

        found = suggestionsFuzzy(suggestions, ['1800florals']);
        if (found) {
            return {
                source: "adbistro",
                provider: "cj",
                title: "1-800-Florals",
                click_url: "http://www.jdoqocy.com/click-8827574-13398600",
                image_url: "//s3.amazonaws.com/autosuggest-files/logos/800florals.png"
            };
        }

        // found = suggestionsFuzzy(suggestions, ['turbotax','turbo tax']);
        // if (found) {
        //     return {
        //         source: "adbistro",
                // provider: "viglink",
        //         title: "TurboTax.com",
        //         click_url: "http://redirect.viglink.com?key=8899039f90b318987523f0cdea660b71&u=http%3A%2F%2Fturbotax.com",
        //         image_url: "//s3.amazonaws.com/autosuggest-files/logos/turbotax.png"
        //     };
        // }

        // found = suggestionsFuzzy(suggestions, ['turbotax','turbo tax']);
        // if (found) {
        //     return {
        //         source: "adbistro",
        //         provider: "mocha",
        //         title: "TurboTax.com - File Your Taxes for Free",
        //         click_url: "https://link.searchemoji.global/link/r?u=https%3A%2F%2Fturbotax.intuit.com%2F&client_id=BQoLAQ8LAwsOAgsPBA8GDQ&campaign_id=CAQMCQoMAQMJBAMLBA8FBw&cid=p6&environment=production&zi=ffc30730-20bd-11e9-b462-37428acf81aa&zh=181323131",
        //         image_url: "//s3.amazonaws.com/autosuggest-files/logos/turbotax.png"
        //     };
        // }

        // found = suggestionsFuzzy(suggestions, ['hrblock','hr block', 'h&r block','h&rblock','h and r block','handr block']);
        // if (found) {
        //     return {
        //         source: "adbistro",
        //         provider: "mocha",
        //         title: "hrblock.com - Start Your Tax Return Today",
        //         click_url: "https://link.searchemoji.global/link/r?u=http%3A%2F%2Fwww.hrblock.com&client_id=BQoLAQ8LAwsOAgsPBA8GDQ&campaign_id=CAQMCQoMAQMJBAMLBA8FBw&cid=p6&environment=production&zi=e3a99e50-20be-11e9-aa18-13258f8ccff9&zh=193434856",
        //         image_url: "//s3.amazonaws.com/autosuggest-files/logos/hrblock.png"
        //     };
        // }

        found = suggestionsFuzzy(suggestions, ['shoecarnival','shoe carnival']);
        if (found) {
            return {
                source: "adbistro",
                provider: "viglink",
                title: "Shoe Carnival",
                click_url: "http://redirect.viglink.com?key=8899039f90b318987523f0cdea660b71&u=http%3A%2F%2Fshoecarnival.com",
                image_url: "//s3.amazonaws.com/autosuggest-files/logos/shoecarnival.png"
            };
        }

        found = suggestionsFuzzy(suggestions, ['shutterstock','shutter stock']);
        if (found) {
            return {
                source: "adbistro",
                provider: "viglink",
                title: "Shutterstock",
                click_url: "http://redirect.viglink.com?key=8899039f90b318987523f0cdea660b71&u=http%3A%2F%2Fshutterstock.com",
                image_url: "//s3.amazonaws.com/autosuggest-files/logos/shutterstock.png"
            };
        }

        // found = suggestionsFuzzy(suggestions, ['efile','e-file']);
        // if (found) {
        //     return {
        //         source: "adbistro",
        //         provider: "viglink",
        //         title: "E-file.com",
        //         click_url: "http://redirect.viglink.com?key=8899039f90b318987523f0cdea660b71&u=http%3A%2F%2Fe-file.com",
        //         image_url: "//s3.amazonaws.com/autosuggest-files/logos/efile.png"
        //     };
        // }

        // found = suggestionsFuzzy(suggestions, ['microsoft']);
        // if (found) {
        //     return {
        //         source: "adbistro",
        //         provider: "viglink",
        //         title: "Microsoft",
        //         click_url: "http://redirect.viglink.com?key=8899039f90b318987523f0cdea660b71&u=http%3A%2F%2Fmicrosoft.com",
        //         image_url: "//s3.amazonaws.com/autosuggest-files/logos/microsoft.png"
        //     };
        // }

        found = suggestionsFuzzy(suggestions, ['motleyfool','motley fool']);
        if (found) {
            return {
                source: "adbistro",
                provider: "viglink",
                title: "Fool.com",
                click_url: "http://redirect.viglink.com?key=8899039f90b318987523f0cdea660b71&u=http%3A%2F%2Ffool.com",
                image_url: "//s3.amazonaws.com/autosuggest-files/logos/motleyfool.png"
            };
        }

        found = suggestionsFuzzy(suggestions, ['stevemadden','steve madden']);
        if (found) {
            return {
                source: "adbistro",
                provider: "viglink",
                title: "Steve Madden",
                click_url: "http://redirect.viglink.com?key=8899039f90b318987523f0cdea660b71&u=http%3A%2F%2Fstevemadden.com",
                image_url: "//s3.amazonaws.com/autosuggest-files/logos/stevemadden.png"
            };
        }

        // found = suggestionsFuzzy(suggestions, ['tax slayer','taxslayer']);
        // if (found) {
        //     return {
        //         source: "adbistro",
        //         provider: "viglink",
        //         title: "TaxSlayer",
        //         click_url: "http://redirect.viglink.com?key=8899039f90b318987523f0cdea660b71&u=http%3A%2F%2Ftaxslayer.com",
        //         image_url: "//s3.amazonaws.com/autosuggest-files/logos/taxslayer.png"
        //     };
        // }

        // found = suggestionsFuzzy(suggestions, ['libertytax','liberty tax']);
        // if (found) {
        //     return {
        //         source: "adbistro",
        //         provider: "viglink",
        //         title: "Liberty Tax Service",
        //         click_url: "http://redirect.viglink.com?key=8899039f90b318987523f0cdea660b71&u=http%3A%2F%2Flibertytax.com",
        //         image_url: "//s3.amazonaws.com/autosuggest-files/logos/libertytax.png"
        //     };
        // }

        found = suggestionsFuzzy(suggestions, ['kohl']);
        if (found) {
            return {
                source: "adbistro",
                provider: "impact",
                title: "Kohl's",
                click_url: "http://kohls.sjv.io/c/1257166/387223/5349?subId1=US_TB",
                image_url: "//s3.amazonaws.com/autosuggest-files/logos/kohls.png"
            };
        }

        // found = suggestionsFuzzy(suggestions, ['mcafee']);
        // if (found) {
        //     return {
        //         source: "adbistro",
        //         provider: "impact",
        //         title: "McAfee",
        //         click_url: "http://mcafee-consumer-affiliate.mpye.net/c/1257166/503896/3165?subId1=US_TB",
        //         image_url: "//s3.amazonaws.com/autosuggest-files/logos/mcafee.png"
        //     };
        // }

        found = suggestionsFuzzy(suggestions, ['carhartt']);
        if (found) {
            return {
                source: "adbistro",
                provider: "cj",
                title: "Carhartt",
                click_url: "http://www.tkqlhce.com/click-8827574-12680698",
                image_url: "//s3.amazonaws.com/autosuggest-files/logos/carhartt.png"
            };
        }

        found = suggestionsFuzzy(suggestions, ['enterprise']);
        if (found) {
            return {
                source: "adbistro",
                provider: "impact",
                title: "Enterprise",
                click_url: "http://partners.enterprise.com/c/1257166/333524/4720",
                image_url: "//s3.amazonaws.com/autosuggest-files/logos/enterprise.png"
            };
        }

        found = suggestionsFuzzy(suggestions, ['alamo']);
        if (found) {
            return {
                source: "adbistro",
                provider: "impact",
                title: "Alamo",
                click_url: "http://partners.alamo.com/c/1257166/333510/4724",
                image_url: "//s3.amazonaws.com/autosuggest-files/logos/alamo.png"
            };
        }

        found = suggestionsFuzzy(suggestions, ['stubhub','stub hub']);
        if (found) {
            return {
                source: "adbistro",
                provider: "cj",
                title: "StubHub",
                click_url: "http://www.kqzyfj.com/click-8827574-13547456",
                image_url: "//s3.amazonaws.com/autosuggest-files/logos/stubhub.png"
            };
        }

        found = suggestionsFuzzy(suggestions, ['walgreens']);
        if (found) {
            return {
                source: "adbistro",
                provider: "cj",
                title: "Walgreens",
                click_url: "http://www.tkqlhce.com/click-8827574-10835555",
                image_url: "//s3.amazonaws.com/autosuggest-files/logos/walgreens.png"
            };
        }


        return null;
    }
}
