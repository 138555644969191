import { suggestionsFuzzy } from '../functions/suggestions_fuzzy';
import { suggestionsBlackFriday } from '../functions/suggestions_black_friday';

export default class WalmartAd
{
    static adToUse(suggestions) {
        let found = suggestionsFuzzy(suggestions, ['walmart']) || suggestionsBlackFriday(suggestions,['walmart']);
        if (found) {
            return {
                source: "mocha",
                provider: "viglink",
                title: "Walmart",
                click_url: "http://redirect.viglink.com?key=8899039f90b318987523f0cdea660b71&u=http%3A%2F%2Fwalmart.com",
                image_url: "//s3.amazonaws.com/autosuggest-files/logos/walmart.png"
            };
        } else {
            return null;
        }
    }
}
