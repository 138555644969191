exports.impressionEventFromSource = function(source) {
    if(impressionEventMapping[source] !== undefined) {
        return impressionEventMapping[source];
    }
    return '';
};


const impressionEventMapping = {
    'admarketplace': 'ex_admarket_place_direct_autocomplete',
    'awin': 'ex_awin_direct_autocomplete',
    'booking.com': 'ex_bookingdotcom_direct_autocomplete',
    'bookingbuddy': 'ex_booking_buddy_direct_autocomplete',
    'cj': 'ex_cj_direct_autocomplete',
    'homedepot_impact': 'ex_homedepot_direct_autocomplete',
    'mocha': 'ex_mocha_direct_autocomplete',
    'namespace': 'ex_namespace_direct_autocomplete',
    'snapfish': 'ex_snapfish_direct_autocomplete',
    'target_impact': 'ex_target_direct_autocomplete',
    'usatodayftw': 'ex_usatodayftw_tile',
    'viglink': 'ex_viglink_direct_autocomplete'
}