//http://completion.amazon.com/search/complete?search-alias=aps&client=amazon-search-ui&mkt=1&q='+prefix+'&callback=AmazonAutocomplete.AmazonJSONPCallbackHandler_'+this._id;
import fetchJsonp from 'fetch-jsonp';

export default class Amazon {

    constructor(active) {
        this.active = active;
    }
    getResults(term) {
        if(this.active === false) {
            return Promise.resolve([]);
        }
        let qsArgs = "search-alias=aps&client=amazon-search-ui&mkt=1&q=" + encodeURIComponent(term);
        var results = fetchJsonp('//completion.amazon.com/search/complete?' + qsArgs,{
            method: 'GET'
        }).then(response => response.json())
        .then(json => json[1].map(item => { return { source: 'amazon', title: item, click_url: '', image_url: '' }; }))
        .catch(error => []);

        return results;
    }
}