import SuggestionNetworks from "../suggestions/suggestion_networks";

export default class EbayAd 
{
    static adToUse(term, config) {
        if(!['us','--'].includes(config.userConfig.country.toLowerCase())) {
            return null;
        }
        if (term === 'ebay' || term === 'ebay.com') {
            return {
                source: "adbistro", 
                provider: 'mocha',
                title: "Shop eBay", 
                click_url: "https://link.searchemoji.global/link/r?u=https%3A%2F%2Fwww.ebay.com&campaign_id=DgMPCgkKAAoMCQMHBAEHDQ&zsb=ebus", 
                image_url: "//s3.amazonaws.com/autosuggest-files/logos/ebay.png"
            };
        } else {
            return null;
        }
    }
}
