import DuckDuckGo from './duckduckgo';
import HardCoded from './hardcoded';
import Google from './google';
import Amazon from './amazon';
import Yahoo from './yahoo';
import YahooGossip from './yahoo_gossip';

import { fudge } from '../functions/fudge';

export default class SuggestionNetworks {

    constructor(config) {
        this.config = config;

        this.amazon = new Amazon(this.config.suggestionProvider.includes("amazon"));
        this.google = new Google(this.config.suggestionProvider.includes("google"));
        this.hardCoded = new HardCoded(this.config.suggestionProvider.includes("hardcoded"));
        this.yahoo = new Yahoo(this.config.suggestionProvider.includes("yahoo"));
        this.yahooGossip = new YahooGossip(this.config.suggestionProvider.includes("yahoogossip"));
        this.duckduckgo = new DuckDuckGo(this.config.suggestionProvider.includes("duckduckgo"));

    }
    getResults(term) {
        let hardcodedResults = this.hardCoded.getResults(term);
        let googleResults = this.google.getResults(term);
        let amazonResults = this.amazon.getResults(term);
        let yahooResults = this.yahoo.getResults(term);
        let yahooGossipResults = this.yahooGossip.getResults(term);
        let duckduckgoResults = this.duckduckgo.getResults(term);

        let autosuggests = Promise.all([hardcodedResults,googleResults,amazonResults,yahooResults,yahooGossipResults,duckduckgoResults])
            .then(([hcResolved, googleResolved, amazonResolved, yahooResolved, yahooGossipResolved, duckduckgoResolved]) => {
                let results = [];
                let resolvedResults = {
                    "hardcoded": hcResolved,
                    "google": googleResolved,
                    "amazon": amazonResolved,
                    "yahoo": yahooResolved,
                    "yahoogossip": yahooGossipResolved,
                    "duckduckgo": duckduckgoResolved
                };
                for (let provider of this.config.suggestionProvider) {
                    let tempResults = resolvedResults[provider];
                    if(results.length === 0) {
                        results = tempResults;
                    }
                    if(results.length < 5 && tempResults.length >= 5) {
                        results = tempResults;
                    }
                }
                if(this.config.fudgeResults) 
                {
                    return fudge(results);
                }
                return results;
            });
        return autosuggests;
    }
}