
import { stripHTML } from '../functions/strip_html';

export default class HardCoded {

    constructor(active) {
        this.active = active;
        this.hardcoded = {};
        this.hardcoded["yahoo"] = [["yahoo", 0, [131]], ["yahoomail", 0, [131]], ["yahoo\u003cb\u003e mail sign in\u003c\/b\u003e", 0, [131]], ["yahoo\u003cb\u003e Mail\u003c\/b\u003e", 0, [131]], ["yahoo", 0, [131]], ["yahoo\u003cb\u003e finance\u003c\/b\u003e", 0], ["www.yahoo.com", 0], ["yahoo.com", 0, [131]], ["yahoo\u003cb\u003e news\u003c\/b\u003e", 0, [131]]];

        this.hardcoded["youtube"] = [["www.\u003cb\u003eyoutube\u003c\/b\u003e.com", 0, [131]], ["youtube \u003cb\u003eto mp3\u003c\/b\u003e", 0, [131]],
            ["youtube \u003cb\u003evideos\u003c\/b\u003e", 0, [131]], ["youtube.com", 0, [131]],
            ["YouTube", 0, [131]], ["youtube \u003cb\u003emusic\u003c\/b\u003e", 0, [131]], ["You Tube", 0, [131]]];
        this.hardcoded["aol"] = [["AOL \u003cb\u003eemail\u003c\/b\u003e", 0, [131]], ["aol\u003cb\u003e.com mail\u003c\/b\u003e", 0, [131]],
            ["aol \u003cb\u003email\u003c\/b\u003e", 0, [131]], ["aol\u003cb\u003e.com\u003c\/b\u003e", 0, [131]],
            ["www.\u003cb\u003eaol\u003c\/b\u003e.com", 0, [131]], ["aol\u003cb\u003email\u003c\/b\u003e", 0, [131]], ["aol", 0, [131]]];
            
    }
    getResults(term) {
        if(this.active === false) {
            return Promise.resolve([]);
        }
        var hardcodedResults = new Promise((resolve, reject) => {
            if(this.hardcoded[term.trim().toLowerCase()])
            {
                resolve(this.hardcoded[term.trim().toLowerCase()]);
            }
            else {
                resolve([]);
            }
        }).then(json => json.map(item => { return { source: 'google', title: item[0], click_url: '', image_url: '',  strippedTitle: stripHTML(item[0]) }; }))
        .catch(error => []);

        return hardcodedResults;
    }
}