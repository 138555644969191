import fetchJsonp from 'fetch-jsonp';
import { stripHTML } from '../functions/strip_html';

export default class Yahoo {

    constructor(active) {
        this.active = active;
    }
    getResults(term) {
        if(this.active === false) {
            return Promise.resolve([]);
        }
        let qsArgs = "output=jsonp&nresults=10&command=" + encodeURIComponent(term);
        var results = fetchJsonp('https://sugg.search.yahoo.net/sg/?' + qsArgs,{
            method: 'GET'
        }).then(response => response.json())
        .then(json => json.gossip.results.map(item => { return { source: 'yahoo', title: item.key, click_url: '', image_url: '',  strippedTitle: stripHTML(item.key) }; }))
        .catch(error => []);

        return results;
    }
}