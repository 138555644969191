import fetchJsonp from 'fetch-jsonp';

export default class AdMarketplace {
    constructor(config) {
        this.config = config;
        if((typeof config.admVertical === "undefined") || config.admVertical.length === 0) {
            // no adm vertical given, try and get it from the DOM
            const verts = document.getElementsByName("i_id");
            if(verts.length > 0 && verts[0].value.length > 0) {
                this.config.admVertical = verts[0].value.substring(0, verts[0].value.length - 1).toLowerCase().split('_')[0];
            } else {
                this.config.admVertical = "test";
            }
        }
        if((typeof config.admPartnerCode === "undefined") || config.admPartnerCode.length === 0) {
            this.config.admPartnerCode = "spigot_cps2018";
        }
        this.active = config.adProvider.includes("adm");
    }
    getAd(term) {
        if(this.active === false) {
            return Promise.resolve([]);
        }
        if(this.isBlackListed(term) || !['us','--'].includes(this.config.userConfig.country.toLowerCase())) {
            return Promise.resolve([]);
        }
        const qsArgsAdMarketplace = "partner=" + this.config.admPartnerCode + "&sub1=" + this.config.admVertical + "&results-os=0&custom-data=" + window.location.hostname + "&qt=" + encodeURIComponent(term);
        let adMarketPlaceResults = fetchJsonp('//' + this.config.admPartnerCode + '.cps.ampfeed.com/suggestions?' + qsArgsAdMarketplace,{
            method: 'GET'
        })
        .then(response => response.json())
        .then(json => json.paid_suggestions.map(item => this.getParseResultItem(item)))
        .catch(error => []);

        return adMarketPlaceResults;
    }

    getParseResultItem(item) {
        return { 
            source: 'admarketplace', 
            provider: 'admarketplace',
            title: item.term, 
            click_url: item.click_url, 
            image_url: item.image_url,
            impression_url: item.impression_url
        }; 
    }
    isBlackListed(term) {
        const blackList = ['yahoo.com','aol','google classroom','fox news',
        'hotmail',
        'gmail.com',
        'google.com',
        'google maps',
        'pinterest',
        'AOL email',
        'google docs',
        'bank of america',
        'cnn',
        'www.aol.com/',
        'aol.com',
        'you tube',
        'pornhub',
        'www.google.com/',
        'yahoomail',
        'yahoo mail sign in',
        'instagram',
        'espn',
        'craigslist',
        'google translate',
        'cool math games',
        'chase',
        'pandora',
        'www.msn.com/',
        'bing',
        'solitaire',
        'capital one',
        'outlook',
        'pogo',
        'www.google.com',
        'yahoo Mail',
        'www.facebook.com/',
        'indeed'            
            ]
        return (blackList.indexOf(term) > -1);
    }
}