export default class Namespace {

    constructor(config) {
        this.config = config;
        this.locale = this.getLocaleFromCountry(this.config.userConfig.country.toLowerCase());
        if((typeof config.admVertical === "undefined") || config.admVertical.length === 0) {
            // no adm vertical given, try and get it from the DOM
            const verts = document.getElementsByName("i_id");
            if(verts.length > 0 && verts[0].value.length > 0) {
                this.config.admVertical = verts[0].value.substring(0, verts[0].value.length - 1).toLowerCase().split('_')[0];
            } else {
                this.config.admVertical = "test";
            }
        }
        this.active = config.adProvider.includes("namespace");
    }
    getAd(term) {
        if(this.active === false) {
            return Promise.resolve([]);
        }
        // if(this.config.admVertical !== "email" && this.config.admVertical !== "forms" && this.config.admVertical !== "template") {
        //     return Promise.resolve([]);
        // }
        // return Promise.resolve([]);
        if(this.locale === '') {
            return Promise.resolve([]);
        }
        let qsArgs = "p=SSS&u=1&n=5&g=1:2:3&l=" + this.locale + "&a=lookup&q=" + encodeURIComponent(term);
        
        let results = fetch('//suggeng.nsgnav.com/?' + qsArgs,{
            method: 'GET'
        })
        .then(response => response.json())
        .then(json => json.map(item => this.getParseResultItem(item)))
        .then(json => this.whiteListAds(json))
        .then(json => json.length > 0 ? json : [])
        .catch(error => []);

        return results;
    }

    getParseResultItem(item) {
        return { 
            source: 'mocha', 
            provider: 'namespace',
            title: item.display, 
            click_url: "http://navigation.nsgnav.com/query.php?a=nav&p=SSS&l=" + this.locale + "&qid=" + item.qid, 
            image_url: "http://img.nsgnav.com/img/" + item.img
        }; 
    }
    getLocaleFromCountry(country) {
        switch(country) {
            case 'us':
                return 'en-US';
            case '--':
                return 'en-US';
            case 'gb':
                return 'en-GB';
            case 'uk':
                return 'en-GB';
            case 'de':
                return 'de-DE'
        }
        return '';
    }

    whiteListAds(results) {
        const whitelist = [
                            // '123reg',
                            '1-800-petmeds',
                            'abebooks',
                            // 'american eagle',
                            // 'apple vacations',
                            'athleta',
                            'banana republic',
                            'barnes & noble',
                            // 'bed bath & beyond',
                            // 'best buy',
                            'bh cosmetics',
                            'blair',
                            'blinds.com',
                            "bloomingdale's",
                            'blue host',
                            'brownells.com',
                            // 'cheapoair',
                            'checks unlimited',
                            'clinique',
                            'columbia',
                            'coupons.com',
                            'dell',
                            'discount school supply',
                            'dress barn',
                            'eastbay',
                            // 'eharmony',
                            'express',
                            // 'free tax usa',
                            // 'gap',
                            'glassesusa',
                            // 'godaddy',
                            // 'hayneedle',
                            // 'hertz',
                            'hostgator',
                            'journeys',
                            "kiehl's",
                            'merrell',
                            // 'nordstrom',
                            'nutrisystem',
                            'old navy',
                            'origins',
                            // 'pacific sunwear',
                            // 'parts geek',
                            // 'payless shoesource',
                            'perry ellis',
                            'petco',
                            'pier 1 imports',
                            'priceline',
                            'proflowers',
                            'quill',
                            'saks fifth avenue',
                            'sephora',
                            'staples',
                            'straight talk',
                            // 'symantec',
                            // 'tax act',
                            'taylor made golf',
                            // 'the body shop',
                            'the container store',
                            // 'tracfone',
                            'transunion',
                            // 'travelocity',
                            'vans',
                            // 'vistaprint',
                            // 'walgreens',
                            'zazzle'
                        ];
        return results.filter(result => whitelist.indexOf(result.title.toLowerCase()) > -1);
    }
}