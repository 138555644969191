import fetchJsonp from 'fetch-jsonp';

import { stripHTML } from '../functions/strip_html';

export default class YahooGossip {

    constructor(active) {
        this.active = active;
    }
    getResults(term) {
        if(this.active === false) {
            return Promise.resolve([]);
        }
        let queryterm = encodeURIComponent(term);
        var results = fetchJsonp('https://ff.search.yahoo.com/gossip?output=jsonp&command=' + queryterm,{
            method: 'GET',
            headers:{
                "Content-Type": "application/x-suggestions+json"
            }
        }).then(response => response.json())
        .then(json => json.gossip.results.map(item => { return this.getParseResultItem(item); }))
        .catch(error => []);

        return results;
    }

    getParseResultItem(item) {
        return { 
            source: 'yahoogossip', 
            title: item.key, 
            click_url: '', 
            image_url: '',
            strippedTitle: stripHTML(item.key)
        }; 
    }
}