import fetchJsonp from 'fetch-jsonp';

import { stripHTML } from '../functions/strip_html';

export default class Google {

    constructor(active) {
        this.active = active;
    }
    getResults(term) {
        if(this.active === false) {
            return Promise.resolve([]);
        }

        let qsArgsGoogle = "client=hp&hl=en&ds=&q=" + encodeURIComponent(term);
        var googleResults = fetchJsonp('//clients1.google.com/complete/search?' + qsArgsGoogle,{
            method: 'GET'
        }).then(response => response.json())
        .then(json => json[1])
        // .then(json => {
        //     let filtered = json.filter(item => {
        //         var that = stripHTML(String(item[0])).toLowerCase();
        //         return that.indexOf("login") === -1 && that.indexOf("log in") === -1;
        //     });
        //     return filtered;
        // })
        .then(json => json.map(item => { return { source: 'google', title: item[0], click_url: '', image_url: '', strippedTitle: stripHTML(item[0]) }; }))
        .catch(error => {
            return [];
        });

        return googleResults;
    }
}