exports.suggestionsBlackFriday = function(suggestions, testStrings) {
    let found = false;
    suggestions.slice(0,2).forEach((suggestion) => {
        let suggestionLowered = suggestion.strippedTitle.toLowerCase();

        if(suggestionLowered.indexOf("black friday") >= 0) 
        {
            testStrings.forEach((testString) => {
                if(suggestionLowered.indexOf(testString) >= 0) 
                {
                    found = true;
                }
            });
        }
    });
    return found;
};