import BookingBuddyAd from './booking_buddy_ad';
import EbayAd from './ebay_ad';
import HomeDepotAd from './home_depot_ad';
import OurAds from './our_ads';
import TargetAd from './target_ad';
import WalmartAd from './walmart_ad';

import Ads from './ads';

export default class CustomAds {

    constructor(config) {
        this.config = config;
    }
    getAd(suggestions, term) {
        if(this.config.adNetworksEnabled === false) {
            return null;
        }
        let adResolved = null;

        // Search suggestions for booking buddy and insert our ad
        // let bookingBuddyAd = BookingBuddyAd.adToUse(suggestions);
        // if(bookingBuddyAd !== null) {
        //     adResolved = bookingBuddyAd;
        // }

        // Search suggestions for home depot and insert our ad
        let homeDepotAd = HomeDepotAd.adToUse(suggestions);
        if(homeDepotAd !== null) {
            adResolved = homeDepotAd;
        }

        // Search suggestions for walmart and insert our ad
        let walmartAd = WalmartAd.adToUse(suggestions);
        if(walmartAd !== null) {
            adResolved = walmartAd;
        }
        // Search suggestions for target and insert our ad
        let targetAd = TargetAd.adToUse(suggestions);
        if(targetAd !== null) {
            adResolved = targetAd;
        }

        // Search suggestions for our ads
        let ourAd = OurAds.adToUse(suggestions);
        if(ourAd !== null) {
            adResolved = ourAd;
        }

        return adResolved;
    }
}
