import { suggestionsFuzzy } from './suggestions_fuzzy';


// Figure out if ad should be placed at the bottom of the autosuggest or not
exports.shouldBeAtBottom = function(ad,suggestions) {
    const autosuggestMatches = [
            'aliexpress',' ali express',
            'amazon', 'amazonprime',
            'ancestry',
            'audible',
            'aws',
            'chewy',
            'ebates',
            'ebay',
            'experian',
            'facebook','facebook account',
            'hotels.com',
            'hrblock.com',
            'jaguar',
            'jc penney','jcpenney','jcp',
            'kohls',
            'landrover','land rover',
            'macys','macy\'s',
            'michaelkors','michael kors',
            'pearlevision','pearle vision','www.pearlevision',
            'pizzahut','pizza hut',
            'quickbooks','quick books',
            'realtor.com',
            'samsung note 9','samsung',
            'transunion',
            'trivago',
            'turbotax',
            'verizon',
            'vrbo',
            'wellsfargo','wells fargo',
            'wix'
        ];
    if(ad.source === "admarketplace" && suggestions.length > 0) {
        let found = suggestionsFuzzy(suggestions, autosuggestMatches);
        return !found;
    }
    return false;
  };


  